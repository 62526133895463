import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { Transforms } from "slate";
import { useSlateStatic, ReactEditor } from "slate-react";
import { fontFamilyMap } from "../../utils/font";
import { formatDate } from "../../utils/helper";

const Signed = (props) => {
  const editor = useSlateStatic();
  const { attributes, element, children } = props;
  const { signature, signedBy, signedOn, signedText, fontFamily } = element;
  const [selected, setSelected] = useState(false);

  const onSelect = () => {
    setSelected(!selected);
  };

  const onDelete = () => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.setNodes(
      editor,
      {
        type: "signature",
        signature: null,
        fontFamily: "",
        signedBy: "",
        signedOn: formatDate(new Date(), "MM/DD/YYYY"),
        signedText: "",
        children: [{ text: "" }],
      },
      { at: path }
    );
  };

  const renderSign = () => {
    if (signature) {
      return (
        <img
          src={signature}
          alt={"signature"}
          style={{ width: "200px", height: "auto", marginBottom: "4px" }}
        />
      );
    } else if (signedText) {
      return (
        <span
          className="signed-text"
          style={{ fontFamily: fontFamilyMap[fontFamily] }}
        >
          {signedText}
        </span>
      );
    }
    return null;
  };

  return (
    <div className="signature-signed-wrapper" {...attributes}>
      <span className="signature-signed-span" contentEditable={false}>
        {selected ? (
          <IconButton className="signed-btn-del" onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        ) : null}
        <button
          className="signed-btn"
          onClick={onSelect}
          style={{
            background: "transparent",
            border: "none",
            textAlign: "left",
          }}
        >
          {renderSign()}
          <div style={{ fontWeight: "bold" }}>{signedBy || ""}</div>
          <div style={{ fontSize: "10px", color: "#ccc", marginTop: "4px" }}>
            {formatDate(signedOn, "MM/DD/YYYY")}
          </div>
        </button>
      </span>
      {children}
    </div>
  );
};

export default Signed;
