const ImageSelectorStyles = (theme) => ({
  root: {},
  tabs: {
    "& button": {
      fontSize: "12px",
      fontWeight: "500",
      textTransform: "capitalize",
      minHeight: "40px",
      padding: "10px 30px",
      marginRight: "14px",
      borderRadius: "7px",
      marginBottom: "12px",
      color: theme?.palette?.editor?.textColor,
      "&.hidden": {
        display: "none",
      },
      "&.active": {
        backgroundColor: theme?.palette?.editor?.background,
        border: `1px solid ${theme?.palette?.editor?.activeColor}`,
        "& svg": {
          "& path": {
            stroke: theme?.palette?.editor?.activeColor,
          },
        },
      },
      "&:hover": {
        backgroundColor: theme?.palette?.editor?.background,
        color: theme?.palette?.editor?.activeColor,
        border: `1px solid ${theme?.palette?.editor?.activeColor}`,
        "& svg": {
          "& path": {
            stroke: theme?.palette?.editor?.activeColor,
          },
        },
      },
      "& svg": {
        marginRight: "8px",
        width: "16px",
        height: "16px",
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
  tab: {},
  imsRightWrpr: {
    height: "300px",
    overflow: "auto",
    backgroundColor: theme?.palette?.editor?.background,
    padding: "0 8px",
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },

  dialogWrapper: {
    "& .MuiDialog-paper": {
      borderRadius: "12px",
      backgroundColor: theme?.palette?.editor?.background,
    },
    "& .primaryBtn": {
      background: "#2563eb !important",
      boxShadow: "0px 8px 24px rgba(30, 64, 175, 0.08)",
      borderRadius: "8px !important",
      height: "28px !important",
      fontWeight: "600 !important",
      fontSize: "12px !important",
      color: "#ffffff !important",
      border: "1px solid #2563eb !important",
      width: "auto !important",
      marginLeft: "0px !important",
    },
    "& .secondaryBtn": {
      background: "#ffffff !important",
      boxShadow: "0px 8px 24px rgba(30, 64, 175, 0.08)",
      borderRadius: "8px !important",
      height: "28px !important",
      fontWeight: "600 !important",
      fontSize: "12px !important",
      color: "#2563eb !important",
      border: "1px solid #2563eb !important",
      width: "auto !important",
      marginRight: "8px !important",
    },
  },
  titleTypo: {
    fontSize: "16px",
    fontWeight: 500,
    color: theme?.palette?.editor?.textColor,
  },
  addLinkField: {
    "& .MuiOutlinedInput-input": {
      fontSize: "12px",
      fontWeight: 500,
      color: theme?.palette?.editor?.textColor,
    },
    "& .MuiFormHelperText-root": {
      color: theme?.palette?.editor?.textColor,
    },
    "& .MuiOutlinedInput-root": {
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.16)",
      borderRadius: "7px",
      "& fieldset": {
        borderColor: "#D8DDE1",
      },
      "&:hover fieldset": {
        borderColor: "#64748B",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#2563EB",
      },
      "& .MuiFormLabel-root": {},
    },
  },
  addLinkBtn: {
    borderRadius: "5px",
    background: "#E7EDF7",
    width: "20px",
    height: "20px",
    "& svg": {
      width: "16px",
      height: "16px",
    },
  },
  closeBtn: {
    backgroundColor: "#F4F6F9",
    color: "#64748B",
    fontSize: "14px",
    fontWeight: 500,
    padding: "4px 22px",
    textTransform: "none",
    border: "1px solid #D8DDE1",
    "&:hover": {
      border: "1px solid #64748B",
    },
  },
  saveBtn: {
    background: "#2563EB",
    fontSize: "14px",
    fontWeight: 500,
    padding: "4px 24px",
    textTransform: "none",
  },
});

export default ImageSelectorStyles;
