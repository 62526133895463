const fieldStyle = [
  {
    tab: "General",
    value: "general",
    fields: [
      {
        label: "Field Name",
        key: "name",
        type: "text",
      },
      {
        label: "Field Type",
        key: "element",
        type: "textOptions",
        options: [
          {
            label: "Textbox",
            value: "text",
          },
          {
            label: "Email",
            value: "email",
          },
          {
            label: "TextArea",
            value: "textArea",
          },
        ],
      },
      {
        label: "Placeholder",
        key: "placeholder",
        type: "text",
      },
      {
        label: "Is required",
        key: "required",
        type: "selectBox",
        placeholder: "Is required",
      },
    ],
  },
  {
    tab: "Padding",
    value: "bannerSpacing",
    fields: [
      {
        label: "Padding",
        key: "bannerSpacing",
        type: "bannerSpacing",
      },
    ],
  },
  {
    tab: "Border",
    value: "border",
    fields: [
      {
        label: "Border Radius",
        key: "borderRadius",
        type: "borderRadius",
      },
      {
        label: "Border Width",
        key: "borderWidth",
        type: "text",
        placeholder: "1px",
      },
      {
        label: "Border Style",
        key: "borderStyle",
        type: "textOptions",
        options: [
          {
            text: "Solid",
            value: "solid",
          },
          {
            text: "Dotted",
            value: "dotted",
          },
          {
            text: "Dashed",
            value: "dashed",
          },
        ],
        renderOption: (option) => {
          return <span>{option.text}</span>;
        },
      },
    ],
  },
  {
    tab: "Colors",
    value: "colors",
    fields: [
      {
        label: "Text Color",
        key: "textColor",
        type: "color",
        needPreview: true,
      },
      {
        label: "Background Color",
        key: "bgColor",
        type: "color",
      },
      {
        label: "Border Color",
        key: "borderColor",
        type: "color",
      },
    ],
  },
  {
    tab: "Position",
    value: "position",
    fields: [
      {
        label: "Set Postion (Vertical & Horizantal)",
        key: "alignment",
        type: "alignment",
      },
    ],
  },
  {
    tab: "Size",
    value: "gridSize",
    fields: [
      {
        label: "Grid Size",
        key: "grid",
        type: "gridSize",
      },
      {
        label: "Height",
        key: "height",
        type: "text",
        placeholder: "40px",
      },
    ],
  },
];

export default fieldStyle;
