import React from "react";
import { insertCarousel } from "../../utils/carousel";
import { Carousal } from "../../common/iconslist";
import ToolbarIcon from "../../common/ToolbarIcon";

const CarouselButton = (props) => {
  const { editor, icoBtnType } = props;
  const handleClick = () => {
    insertCarousel(editor);
  };
  return (
    <ToolbarIcon
      title="Carousel"
      onClick={handleClick}
      icon={<Carousal />}
      icoBtnType={icoBtnType}
    />
  );
};

export default CarouselButton;
