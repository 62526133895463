import React from "react";
import { Grid, MenuItem, Select, Typography } from "@mui/material";
import { getBreakPointsValue } from "../../../helper/theme";
import useWindowResize from "../../../hooks/useWindowResize";

const TextOptions = (props) => {
  const { value: val, data, onChange, elementProps, classes } = props;
  const { key, isBreakpoint, options, renderOption, width } = data;
  const [size] = useWindowResize();
  const value = isBreakpoint ? getBreakPointsValue(val, size?.device) : val;

  const handleChange = (e) => {
    if (isBreakpoint) {
      onChange({
        [key]: {
          ...getBreakPointsValue(val),
          [size?.device]: e.target.value,
        },
      });
    } else {
      onChange({
        [key]: e.target.value,
      });
    }
  };

  return (
    <>
      <Grid item xs={width || 12} style={{ marginBottom: "5px" }}>
        <Typography
          variant="body1"
          color={"primary"}
          sx={{ fontSize: "14px", fontWeight: "500", marginBottom: "4px" }}
        >
          {data?.label}
        </Typography>
        <Select
          onChange={handleChange}
          value={value || options[0]?.value}
          placeholder={data?.label}
          fullWidth
          size="small"
          style={{ marginBottom: "16px" }}
          sx={classes.textOptions}
        >
          {options.map((m) => {
            return (
              <MenuItem key={`${key}_${m.value}`} value={m.value}>
                {renderOption
                  ? renderOption(m, elementProps)
                  : m.label || m.text}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
    </>
  );
};

export default TextOptions;
