import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

const ElementListCard = (props) => {
  const { classes, data } = props;
  const { name, desc, icon } = data;
  return (
    <>
      <Card
        sx={{
          display: "flex",
          width: "100%",
          maxWidth: "324px",
          justifyContent: "start",
          alignItems: "center",
          background: "transparent",
          boxShadow: "none",
        }}
      >
        <CardMedia component="div" sx={classes.cardMedia} alt={name}>
          {icon}
        </CardMedia>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto", padding: "8px !important" }}>
            <Typography
              component="div"
              variant="h6"
              style={{ fontSize: "14px" }}
            >
              {name}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
              style={{ fontSize: "12px" }}
            >
              {desc}
            </Typography>
          </CardContent>
        </Box>
      </Card>
    </>
  );
};

export default ElementListCard;
