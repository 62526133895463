import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Grid,
  TextField,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SignatureOptions from "./SignatureOptions";
import "./Signature.css";
import {
  DrawSignature,
  PencilIcon,
  TypeSignature,
  UploadSignature,
} from "../../common/EditorIcons";
import useCommonStyle from "../../commonStyle";
import { useEditorContext } from "../../hooks/useMouseMove";
import { validationMethods } from "../Form/FormElements/validations";

const BrushSizes = [1, 5, 7];

const SignaturePopup = (props) => {
  const { theme } = useEditorContext();
  const classes = useCommonStyle(theme);
  const { onSave, onClear, customProps, className } = props;
  const { readOnly, metadata, isSignerDetailsRequired = false } = customProps;
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(0);
  const SeletectedTab = SignatureOptions[tab];
  const [signedData, setSignedData] = useState({
    signedOn: new Date(),
    signature: "",
    signedText: "",
    signedBy: "",
    signedByEmail: ""
  });
  const [brush, setBrush] = useState({
    size: 1,
    color: "#000000",
  });
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    await customProps?.services("workFlowAction", {
      resource_id: customProps?.page_id,
    });
    let isValidEmail = "";
    if (isSignerDetailsRequired && signedData?.signedByEmail !== ""){
      isValidEmail = validationMethods?.isEmail(signedData?.signedByEmail);
    }
    
    if (isValidEmail === "Enter valid email address"){
      alert(isValidEmail)
    } else {
      onSave(signedData);
      // manual delay
      setTimeout(() => {
        if (metadata && metadata?.actionHandler) {
          metadata?.actionHandler("signed", signedData);
        }
        handleClose();
      }, 0);
    }
  };

  const handleClear = () => {
    onClear();
    handleClose();
  };

  const onDataChange = (data) => {
    setSignedData({
      ...signedData,
      ...data,
    });
  };

  const onChange = (e) => {
    e?.stopPropagation();
    setSignedData({
      ...signedData,
      [e.target.name]: e.target.value,
    });
  };

  const onBrushSize = (val) => () => {
    setBrush({
      ...brush,
      size: val,
    });
  };

  const onBrushColor = (e) => {
    setBrush({
      ...brush,
      color: e.target.value,
    });
  };

  const onUploadDone = (data) => {
    setSignedData({
      ...signedData,
      ...data,
    });
  };

  const onTabChange = (e, newValue) => {
    e?.stopPropagation();
    setTab(newValue);
    setSignedData({
      signedOn: new Date(),
      signature: "",
      signedText: "",
      signedBy: "",
      signedByEmail: ""
    });
  };
  let isEmpty = signedData?.signature === "" && signedData?.signedText === ""
  if (isSignerDetailsRequired){
    isEmpty = (signedData?.signature === "" && signedData?.signedText === "") || signedData?.signedBy === "" || signedData?.signedByEmail === ""
  }

  return (
    <>
      <div className={`signature-btn-container`}>
        <Button contentEditable={false} onClick={handleOpen}>
          Sign Here
        </Button>
      </div>
      <Dialog
        className={`${className} dialogComp`}
        open={open}
        onClose={handleClose}
        fullWidth
        sx={classes.signaturePopup}
      >
        <Grid container>
          <Grid item xs={12} sx={{ p: 3 }}>
            <DialogTitle sx={{ p: 0, pb: 2 }}>
              <Grid container justifyContent={"space-between"}>
                <Grid item>
                  <Typography
                    variant="h6"
                    className="popupTitle"
                    color={"primary"}
                  >
                    SIGNATURE
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container>
                    {customProps?.customComponents?.signature?.header?.map(
                      (elem, index) => (
                        <Grid key={index} sx={{ pr: 1 }}>
                          {elem}
                        </Grid>
                      )
                    )}
                    <Grid item>
                      <IconButton
                        onClick={handleClose}
                        className="close-popupbtn"
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent sx={{ p: 0 }} className="af-signature-popup">
              <div className="signature-btn-grps">
                <Tabs
                  scrollButtons="auto"
                  variant="scrollable"
                  value={tab}
                  onChange={(e, newValue) => {
                    onTabChange(e, newValue);
                  }}
                  aria-label="Element Tabs"
                >
                  <Tab
                    label={
                      <Grid className="dflex alignCenter">
                        <DrawSignature />
                        <span className="ml-1">Draw Signature</span>
                      </Grid>
                    }
                  />
                  <Tab
                    label={
                      <Grid className="dflex alignCenter">
                        <TypeSignature />
                        <span className="ml-1">Type Signature</span>
                      </Grid>
                    }
                  />
                  <Tab
                    label={
                      <Grid className="dflex alignCenter">
                        <UploadSignature />
                        <span className="ml-1">Upload Signature</span>
                      </Grid>
                    }
                  />
                </Tabs>
              </div>
              <div className="signature-tab-content">
                {SeletectedTab ? (
                  <SeletectedTab
                    onDataChange={onDataChange}
                    customProps={{ ...(customProps || {}), brush: brush }}
                    onUploadDone={onUploadDone}
                  />
                ) : null}
              </div>
              <Grid
                container
                sx={{ pt: 2 }}
                spacing={1}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid
                  item
                  xs={6}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Grid style={{ marginRight: "8px" }}>
                    <label htmlFor="signedBy">Name:</label>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="signedBy"
                      name="signedBy"
                      placeholder="Enter Name"
                      size="small"
                      onChange={onChange}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Grid style={{ marginRight: "8px" }}>
                    <label htmlFor="signedOn">Date:</label>
                  </Grid>
                  <Grid item onClick={(e) => {e?.stopPropagation()}} xs={12}>
                    <DatePicker
                      showIcon
                      id="signedOn"
                      name="signedOn"
                      selected={
                        signedData?.signedOn
                          ? new Date(signedData?.signedOn)
                          : new Date()
                      }
                      value={signedData?.signedOn || ""}
                      dateFormat={"MM/dd/yyyy"}
                      onChange={(date) => {
                        setSignedData({
                          ...signedData,
                          signedOn: date
                            ? new Date(date).toISOString().split("T")[0]
                            : "",
                        });
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Grid style={{ marginRight: "8px" }}>
                    <label htmlFor="signedByEmail">Email:</label>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="signedByEmail"
                      name="signedByEmail"
                      placeholder="Enter Email"
                      size="small"
                      onChange={onChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className="dialog-actions-si" sx={{ p: 0, pt: 2 }}>
              {tab === 0 ? (
                <Grid container alignItems={"center"}>
                  <Grid item sx={{ mr: 4 }}>
                    <input
                      type="color"
                      className="brushcolorpic signColorPicker"
                      onChange={onBrushColor}
                      size="4"
                      value={brush?.color}
                    />
                  </Grid>
                  <Grid item>
                    <Grid className="dflex alignCenter sizeIcons">
                      {BrushSizes.map((m) => {
                        return (
                          <IconButton
                            onClick={onBrushSize(m)}
                            key={`sit-bs-${m}`}
                            disableRipple
                            className={brush.size === m ? "activeBrush" : ""}
                          >
                            <span style={{ width: 20 + m, height: 20 + m }}>
                              <PencilIcon
                                style={{ width: 20 + m, height: 20 + m }}
                              />
                            </span>
                          </IconButton>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              <>
                {!readOnly ? (
                  <Button onClick={handleClear} className="secondaryBtn">
                    Delete
                  </Button>
                ) : null}
                <Button
                  onClick={handleSave}
                  className={`primaryBtn ${isEmpty ? "disabled" : ""}`}
                  disabled={isEmpty}
                >
                  Save
                </Button>
              </>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

// export default SignaturePopup;
export default SignaturePopup;
