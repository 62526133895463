import { withReact } from "slate-react";
import { withHistory } from "slate-history";
import withLinks from "../plugins/withLinks";
import withTables from "../plugins/withTable";
import withEmbeds from "../plugins/withEmbeds";
import withEquation from "../plugins/withEquation";
import withMentions from "../plugins/withMentions";
import withLayout from "../plugins/withLayout";
import withHtml from "../plugins/withHTML";

const withCommon = (props, rest = {}) => {
  return rest.needLayout
    ? withHtml(
        withEquation(
          withLayout(
            withHistory(
              withEmbeds(withTables(withLinks(withMentions(withReact(props)))))
            )
          )
        )
      )
    : withHtml(
        withEquation(
          withHistory(
            withEmbeds(withTables(withLinks(withMentions(withReact(props)))))
          )
        )
      );
};

export default withCommon;
