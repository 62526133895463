import { fontOptions } from "../../utils/font";

const appHeaderStyle = [
  {
    tab: "Logo",
    value: "appLogo",
    fields: [
      {
        label: "App Title",
        key: "appTitle",
        type: "text",
        placeholder: "Leave Empty, If only Logo",
      },
      {
        label: "Title Font Family",
        key: "titleFontFamily",
        type: "textOptions",
        options: fontOptions,
        width: 7,
        renderOption: (option) => {
          return <span style={{ fontFamily: option.value }}>{"LOGO"}</span>;
        },
      },
      {
        label: "Title Font Size",
        key: "logoFontSize",
        type: "fontSize",
        width: 5,
        placeholder: "16px",
      },
      {
        label: "Logo Image URL",
        key: "appLogo",
        type: "text",
      },
      {
        label: "Logo Height",
        key: "logoHeight",
        type: "text",
        placeholder: "40px",
      },
      {
        label: "App Logo",
        key: "appLogo",
        type: "backgroundImage",
      },
      {
        label: "Move Logo to Right",
        key: "isLogoRight",
        type: "selectBox",
        placeholder: "Move Logo to Right",
      },
    ],
  },
  {
    tab: "General",
    value: "general",
    fields: [
      {
        label: "Menu Font Family",
        key: "fontFamily",
        type: "textOptions",
        width: 7,
        options: fontOptions,
        renderOption: (option) => {
          return <span style={{ fontFamily: option.value }}>Home</span>;
        },
      },
      {
        label: "Menu Font Size",
        key: "fontSize",
        type: "fontSize",
        placeholder: "16px",
        width: 5,
      },
    ],
  },
  {
    tab: "Menus",
    value: "menus",
    fields: [
      {
        label: "Menus",
        key: "menus",
        type: "menusArray",
      },
    ],
  },
  {
    tab: "Banner Spacing",
    value: "bannerSpacing",
    fields: [
      {
        label: "Banner Spacing",
        key: "bannerSpacing",
        type: "bannerSpacing",
      },
    ],
  },
  {
    tab: "Border Radius",
    value: "borderRadius",
    fields: [
      {
        label: "Border Radius",
        key: "borderRadius",
        type: "borderRadius",
      },
    ],
  },
  {
    tab: "Colors",
    value: "colors",
    fields: [
      {
        label: "Text Color",
        key: "textColor",
        type: "color",
        needPreview: false,
      },
      {
        label: "Background Color",
        key: "bgColor",
        type: "color",
      },
      {
        label: "Border Color",
        key: "borderColor",
        type: "color",
      },
    ],
  },
  {
    tab: "Hover Colors",
    value: "hoverColors",
    fields: [
      {
        label: "Hover Text Color",
        key: "textColorHover",
        type: "color",
        needPreview: false,
      },
      {
        label: "Hover Background Color",
        key: "bgColorHover",
        type: "color",
      },
      {
        label: "Hover Border Color",
        key: "borderColorHover",
        type: "color",
      },
    ],
  },
];

export default appHeaderStyle;
