const accordionTitleBtnStyle = [
  {
    tab: "Colors",
    value: "colors",
    fields: [
      {
        label: "Text Color",
        key: "textColor",
        type: "color",
        needPreview: true,
      },
      {
        label: "Background Color",
        key: "bgColor",
        type: "color",
      },
      {
        label: "Border Color",
        key: "borderColor",
        type: "color",
      },
    ],
  },
];

export default accordionTitleBtnStyle;
