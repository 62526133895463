import { jsx } from "slate-hyperscript";

const handleTableCell = (el, children) => {
  const wrapChild = children?.map((c) => {
    if (typeof c === "string") {
      return {
        type: "paragraph",
        children: [
          {
            text: c,
          },
        ],
        cellBgColor: "#FFFFFF",
      };
    }

    return c;
  });

  return {
    type: "table-cell",
    overwriteChild: wrapChild,
  };
};

const ELEMENT_TAGS = {
  A: (el) => ({ type: "link", url: el.getAttribute("href") }),
  BLOCKQUOTE: () => ({ type: "quote" }),
  H1: () => ({ type: "headingOne" }),
  H2: () => ({ type: "headingTwo" }),
  H3: () => ({ type: "headingThree" }),
  H4: () => ({ type: "headingFour" }),
  H5: () => ({ type: "headingFive" }),
  H6: () => ({ type: "headingSix" }),
  IMG: (el) => ({ type: "image", url: el.getAttribute("src") }),
  LI: () => ({ type: "list-item" }),
  UL: () => ({ type: "unorderedList" }),
  OL: () => ({ type: "orderedList" }),
  P: () => ({ type: "paragraph" }),
  PRE: () => ({ type: "code" }),
  META: () => ({ type: "paragraph" }),
  STYLE: () => ({ type: "paragraph" }),
  "GOOGLE-SHEETS-HTML-ORIGIN": () => ({ type: "paragraph" }),
  TABLE: (el, children = []) => {
    const bodyChild = children[0]?.children || []
    const firstRowChildren = bodyChild[0]?.children || [];

    return {
      type: "table",
      overwriteChild: bodyChild, // we are not having table-body in our json format, just we are wrapping table-row's inside the table
      rows: bodyChild?.length,
      columns: firstRowChildren?.length,
    };
  },
  THEAD: () => ({ type: "table-head" }),
  TBODY: () => ({ type: "table-body" }),
  TH: handleTableCell,
  TR: () => ({ type: "table-row" }),
  TD: handleTableCell,
  COLGROUP: () => ({ type: "paragraph" }),
  COL: () => ({ type: "paragraph" }),
};

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
};

const deserialize = (el) => {
  if (el.nodeType === 3) {
    const match = /\r|\n/.exec(el.textContent);
    return match ? null : el.textContent;
  } else if (el.nodeType !== 1) {
    return null;
  } else if (el.nodeName === "BR") {
    return "\n";
  }

  const { nodeName } = el;
  let parent = el;

  if (
    nodeName === "PRE" &&
    el.childNodes[0] &&
    el.childNodes[0].nodeName === "CODE"
  ) {
    parent = el.childNodes[0];
  }
  let children = Array.from(parent.childNodes).map(deserialize).flat();

  if (children.length === 0) {
    children = [{ text: "" }];
  }

  if (el.nodeName === "BODY") {
    return jsx("fragment", {}, children);
  }

  if (ELEMENT_TAGS[nodeName]) {
    const { overwriteChild, ...attrs } = ELEMENT_TAGS[nodeName](el, children);

    return jsx("element", attrs, overwriteChild || children);
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el);
    return children.map((child) => jsx("text", attrs, child));
  }

  return children;
};

export default deserialize;
