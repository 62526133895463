import React from "react";

const AddElementIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.17334 1.94688L13.1067 3.69354C14.24 4.19354 14.24 5.02021 13.1067 5.52021L9.17334 7.26688C8.72667 7.46688 7.99334 7.46688 7.54667 7.26688L3.61334 5.52021C2.48 5.02021 2.48 4.19354 3.61334 3.69354L7.54667 1.94688C7.99334 1.74687 8.72667 1.74687 9.17334 1.94688Z"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 7.3335C2.5 7.8935 2.92 8.54016 3.43333 8.76683L7.96 10.7802C8.30667 10.9335 8.7 10.9335 9.04 10.7802L13.5667 8.76683C14.08 8.54016 14.5 7.8935 14.5 7.3335"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 10.6665C2.5 11.2865 2.86667 11.8465 3.43333 12.0998L7.96 14.1132C8.30667 14.2665 8.7 14.2665 9.04 14.1132L13.5667 12.0998C14.1333 11.8465 14.5 11.2865 14.5 10.6665"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AddElementIcon;
