import FormEmail from "./FormEmail";
import FormText from "./FormText";
import FormTextArea from "./FormTextArea";

const FormElements = {
  text: FormText,
  textArea: FormTextArea,
  email: FormEmail,
};

export default FormElements;
