import React, { useState } from "react";
import { useSlateStatic } from "slate-react";
import { Box, Divider, Grid, Tooltip } from "@mui/material";
import { toolbarGroups } from "../toolbarGroups";
import { MarkButton } from "../FormatTools";
import LinkButton from "../../Elements/Link/LinkButton";
import { addMarkData, isBlockActive } from "../../utils/SlateUtilityFunctions";
import ColorPickerButton from "../../common/ColorPickerButton";
import { colors } from "../../Elements/Color Picker/defaultColors";
import VariableButton from "../../Elements/Variables/VariableButton";

const allTools = toolbarGroups.flat();
const BasicToolbar = (props) => {
    const editor = useSlateStatic();

    // props
    const { otherProps: { variableOptions, fontStyleOptions } } = props;

    // state
    const [activeColor, setActiveColor] = useState("#000000");

    const fontStyle = allTools.filter((f) => (f.basic || fontStyleOptions?.includes(f.format)));

    const link = allTools.find((f) => f.format?.indexOf("link") >= 0);

    const handleTextColor = (color) => {
        setActiveColor(color);
        addMarkData(editor, { format: 'color', value: color })
    };

    return (
        <Box component={'div'} className="basic-toolbar">
            <Grid container sx={{padding: '10px'}} alignItems={'center'} justifyContent={'space-between'}>
                <Grid item>
                    <Grid container alignItems={'center'}>
                        <Grid item> 
                            {fontStyle?.map((m, i) => {
                                return (
                                    <MarkButton
                                        key={`pptool_mark_${i}_${m.id}`}
                                        editor={editor}
                                        {...m}
                                    />
                                );
                            })}
                        </Grid>
                        <Grid item>
                            <LinkButton
                                key={link.id}
                                active={isBlockActive(editor, link.format)}
                                editor={editor}
                            />
                        </Grid>
                        <Grid item>
                            <Tooltip title="Font Color">
                                <ColorPickerButton value={activeColor || "#0000"} onSave={(color) => { handleTextColor(color) }} defaultColors={colors} />
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    {(variableOptions && (variableOptions.length > 0)) && <VariableButton
                        placeholder={"Variables"}
                        options={variableOptions}
                    />}
                </Grid>
            </Grid>
            <Divider />
        </Box>
    )
}

BasicToolbar.defaultProps = {
    otherProps : {
        variableOptions: [],
        fontStyleOptions: []
    }
}

export default BasicToolbar;