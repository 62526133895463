import TextIcon from "../../../assets/svg/TextIcon";
import AddElementIcon from "../../../assets/svg/AddElementIcon";
import AddTemplateIcon from "../../../assets/svg/AddTemplateIcon";
import PageSettingsButton from "../../../Elements/PageSettings/PageSettingsButton";

const MENU_OPTIONS = [
  {
    type: "textFormat",
    icon: TextIcon,
    label: "Text Style",
  },
  {
    type: "addElement",
    icon: AddElementIcon,
    label: "Add Element",
  },
  {
    type: "addTemplate",
    icon: AddTemplateIcon,
    label: "Add Template",
  },
  {
    type: "page-settings",
    icon: PageSettingsButton,
    label: "Page Settings",
  },
];

export default MENU_OPTIONS;
