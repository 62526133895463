const TableStyles = () => ({
  tableToolBar: {
    position: "absolute",
    top: "-34px",
    left: 0,
    backgroundColor: "#FFF",
    border: "1px solid rgba(37, 99, 235, 0.32)",
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.16)",
    borderRadius: "0px",
    width: "32px",
    height: "32px",
    overflow: "hidden",
    transition: "all 0.3s",
    "& button": {
      "& svg": {
        width: "16px",
        height: "16px",
      },
    },
    "&.active": {
      width: "auto",
      "& .toggle": {
        rotate: "180deg",
      },
    },
    "& .toolbtn": {
      "& svg": {
        stroke: "rgb(100, 116, 139)",
      },
    },
  },
  table: {
    "& td": {
      padding: "8px 8px",
      border: "1px solid #E0E0E0",
      "&.selection": {
        "& *::selection": {
          background: "transparent",
        },
      },
    },
    "& .selection-area-tc": {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      background: "rgba(149, 218, 255, .3)",
      pointerEvents: "none",
    },
  },
  cellResizer: {
    position: "absolute",
    cursor: "col-resize",
    right: "-2px",
    top: 0,
    background: "transparent",
    width: "4px",
    borderRadius: "0px",
    zIndex: 1,
    "&:hover": {
      background: "#ccc",
    },
  },
});

export default TableStyles;
