const VariableStyles = () => ({
  variablesItem: {
    color: "#2563EB",
    background: "#EEEEEE"
  },
  // variableSelectBtn: {
  //   height: "31px",
  //   borderRadius: "10px",
  //   marginLeft: "8px"
  // }
  variableBtn: {
  background: "#F4F6F9",
  color: "#64748B",
  fontSize: "14px",
  fontWeight: 500,
  padding: "4px 22px",
  textTransform: "none",
  border: "1px solid #D8DDE1",
  height: "36px",
  paddingLeft: '8px',
  paddingRight: '18px',
  "& svg": {
    width: '20px',
    height: '20px',
    "& path": {
      stroke: "#64748B",
    },
  },
  "&:hover": {
    border: "1px solid #64748B",
  },
  '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input':{
    paddingRight: '5px'
  }
  },
});

export default VariableStyles;
