import React from "react";
import {
  Grid,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";

const SelectBox = (props) => {
  const { value, data, onChange } = props;
  const { key, placeholder } = data;

  const handleChange = (e) => {
    onChange({ [key]: e.target.checked });
  };

  return (
    <Grid item xs={12} style={{ marginBottom: "12px" }}>
      <FormControlLabel
        control={
          <Checkbox value={value} checked={value} onChange={handleChange} />
        }
        label={placeholder}
      />
      <FormHelperText>{data?.helperText}</FormHelperText>
    </Grid>
  );
};

export default SelectBox;
