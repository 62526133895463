import React, { useState } from "react";
import { signedTextFonts } from "../../../utils/font";
import {
  Grid,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const TypeSignature = (props) => {
  const { onDataChange } = props;
  const [name, setName] = useState("");
  const [fontFamily, setFontFamily] = useState("");

  const onChange = (e) => {
    setName(e.target.value);
    onDataChange({
      signedText: e.target.value,
      signature: "",
    });
  };

  const onFontFamilyChange = (val) => () => {
    setFontFamily(val);
    onDataChange({
      fontFamily: val,
    });
  };

  const renderFontFamily = () => {
    return signedTextFonts.map((m, i) => {
      return (
        <Grid
          item
          key={`typesign-ff-${m.value}`}
          padding={1}
          xs={4}
          style={{ textAlign: "center" }}
        >
          <Button
            fullWidth
            onClick={onFontFamilyChange(m.value)}
            className={m.value === fontFamily ? "active" : ""}
          >
            <Typography style={{ fontFamily: m.text, overflow: "hidden", textOverflow: "ellipsis", warp: "noWarp" , maxWidth: "120px", }}>{name || "Signature"}</Typography>
          </Button>
        </Grid>
      );
    });
  };

  return (
    <div className="signature-tab-1-wrapper">
      <div className="typesignature-input-wrapper">
        <Grid container>
          <Grid item padding={1} xs={12} style={{ textAlign: "center" }}>
            <TextField
              value={name}
              onChange={onChange}
              size="small"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setName("")}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </div>
      <Grid container className="typesignature-fontfamily">
        {renderFontFamily()}
      </Grid>
    </div>
  );
};

export default TypeSignature;
