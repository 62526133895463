import React from "react";
import { Box } from "@mui/material";
import { getTRBLBreakPoints, getBreakPointsValue } from "../../../helper/theme";

const FormTextArea = (props) => {
  const { fieldProps } = props;
  const {
    bannerSpacing,
    height,
    borderRadius,
    borderStyle,
    borderWidth,
    borderColor,
    textColor,
    bgColor,
    ...rest
  } = fieldProps;
  const onChange = (e) => {
    e.preventDefault();
  };

  return (
    <div style={{ width: "100%", display: "flex" }} contentEditable={false}>
      <Box
        component={"textarea"}
        {...rest}
        onChange={onChange}
        sx={{
          width: "100%",
          border: `1px solid ${borderColor || "#FFF"}`,
          padding: {
            ...getTRBLBreakPoints(bannerSpacing),
          },
          height: height || "150px",
          borderColor: borderColor || "transparent",
          borderWidth: borderWidth || "1px",
          borderRadius: {
            ...getBreakPointsValue(
              borderRadius || {},
              null,
              "overrideBorderRadius",
              true
            ),
          },
          borderStyle: borderStyle || "solid",
          color: textColor || "#000",
          background: bgColor || "transparent",
          fontSize: '14px',
        }}
      />
    </div>
  );
};

export default FormTextArea;
