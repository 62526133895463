import React, { useState } from "react";
import { Button, ButtonGroup, Grid, Typography } from "@mui/material";
import FormatColorResetIcon from "@mui/icons-material/FormatColorReset";
import WidthFullIcon from "@mui/icons-material/WidthFull";
import WidthNormalIcon from "@mui/icons-material/WidthNormal";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FormatClearIcon from "@mui/icons-material/FormatClear";
import {
  Dropdown,
  MarkButton,
  TextSize,
  BlockButton,
  AccordionButton,
} from "../FormatTools";
import { toolbarGroups } from "../toolbarGroups";
import ColorPicker from "../../Elements/Color Picker/ColorPicker";
import {
  activeMark,
  isBlockActive,
  toggleBlock,
  getBlockActive,
  upateBlockActive,
  addMarkData,
} from "../../utils/SlateUtilityFunctions";
import LinkButton from "../../Elements/Link/LinkButton";
import { getPageSettings, updatePageSettings } from "../../utils/pageSettings";
import { AllColors } from "../../Elements/Color Picker/ColorButtons";
import { fontFamilyMap } from "../../utils/font";
import { getBorderColor } from "../../utils/helper";

const allTools = toolbarGroups.flat();
const ButtonComp = {
  AccordionButton: AccordionButton,
};

const TextFormat = (props) => {
  const { classes, editor } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [type, setType] = useState(null);
  const open = Boolean(anchorEl);
  const fontFamily = allTools.find((f) => f.format === "fontFamily");
  const fontWeight = allTools.find((f) => f.format === "fontWeight");
  const fontStyle = allTools.filter((f) => f.type === "mark");
  const fontAlign = allTools.filter((f) => f.format?.indexOf("align") >= 0);
  const link = allTools.find((f) => f.format?.indexOf("link") >= 0);
  const lists = allTools.filter((f) => f.group?.indexOf("list") >= 0);
  const typo = allTools.filter((f) => f.group?.indexOf("typography") >= 0);
  const { pageProps } = getPageSettings(editor)?.element || {};
  const { pageWidth } = pageProps;
  const { isActive: isBlockQuoteActive, props: bqProps } = getBlockActive(
    editor,
    "blockquote"
  );
  const { color: bqColor, bgColor: bqBgColor } = bqProps || {
    color: "",
    bgColor: "",
  };

  const handlePageWidth = (width) => () => {
    updatePageSettings(editor, { ...(pageProps || {}), pageWidth: width });
  };

  const handleColorPicker = (type) => (e) => {
    setType(type);
    setAnchorEl(e.currentTarget);
  };

  const updateBlockQuote = (attr) => {
    const upAttr = {
      ...(bqProps || {}),
      ...attr,
    };
    delete upAttr.children;
    delete upAttr.type;
    if (isBlockQuoteActive && (upAttr.color || upAttr.bgColor)) {
      upateBlockActive(editor, "blockquote", upAttr);
    } else {
      toggleBlock(editor, "blockquote", editor.selection, upAttr);
    }
  };

  const onSelectColor = (color) => () => {
    const attr = {
      [type]: color,
    };
    updateBlockQuote(attr);
  };

  const handleQuote = (attr) => () => {
    updateBlockQuote(attr);
  };

  const onClosePicker = () => {
    setAnchorEl(null);
  };

  const handleDefault =
    ({ format, val }) =>
      () => {
        const value = val ? val : "inherit";
        addMarkData(editor, { format, value });
      };

  return (
    <Grid container sx={classes.textFormatWrapper}>
      <Grid item xs={12}>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid item>
            <Typography
              variant="body1"
              color={"primary"}
              sx={classes.typoLabel}
            >
              Font Family
            </Typography>
          </Grid>
          <Grid item>
            <Button
              sx={classes.defaultBtn}
              startIcon={<FormatClearIcon />}
              onClick={handleDefault({
                format: "fontFamily",
                val: Object.values(fontFamilyMap)[0],
              })}
            >
              Default
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={classes.textFormatField}>
          <Dropdown classes={classes} {...fontFamily} editor={editor} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="body1" color={"primary"} sx={classes.typoLabel}>
            Font Weight
          </Typography>
          <Grid item xs={12} sx={classes.textFormatField}>
            <Dropdown
              classes={classes}
              {...fontWeight}
              editor={editor}
              width={"100%"}
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" color={"primary"} sx={classes.typoLabel}>
            Font Size
          </Typography>
          <Grid item xs={12} sx={classes.textFormatCG}>
            <TextSize
              classes={classes}
              format={"fontSize"}
              activeMark={activeMark}
              editor={editor}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={classes.textFormatField}>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid item>
            <Typography
              variant="body1"
              color={"primary"}
              sx={classes.typoLabel}
            >
              Text Color
            </Typography>
          </Grid>
          <Grid item>
            <Button
              sx={classes.defaultBtn}
              startIcon={<FormatColorResetIcon />}
              onClick={handleDefault({ format: "color" })}
            >
              Default
            </Button>
          </Grid>
        </Grid>
        <Grid sx={classes.textFormatColorWrpr}>
          <ColorPicker
            format={"color"}
            activeMark={activeMark}
            editor={editor}
            showHex={false}
            rounded={true}
            title="Text Color"
            key={"11_cc"}
            id={"11_cc"}
            classes={classes}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sx={classes.textFormatField}>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid item>
            <Typography
              variant="body1"
              color={"primary"}
              sx={classes.typoLabel}
            >
              Text Background Color
            </Typography>
          </Grid>
          <Grid item>
            <Grid item>
              <Button
                sx={classes.defaultBtn}
                startIcon={<FormatColorResetIcon />}
                onClick={handleDefault({ format: "bgColor" })}
              >
                Default
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={classes.textFormatColorWrpr}>
          <ColorPicker
            format={"bgColor"}
            activeMark={activeMark}
            editor={editor}
            showHex={false}
            rounded={true}
            title="Text Background Color"
            key={"12_cc"}
            classes={classes}
            id={"12_cc"}
          />
        </Grid>
      </Grid>
      <Grid item xs={6} sx={classes.textFormatField}>
        <Typography variant="body1" color={"primary"} sx={classes.typoLabel}>
          Text Alignment
        </Typography>
        <Grid item xs={12} sx={classes.textFormatCG}>
          {fontAlign?.map((m, i) => {
            return (
              <BlockButton
                key={`pptool_block_${i}_${m.id}`}
                editor={editor}
                {...m}
              />
            );
          })}
        </Grid>
      </Grid>
      <Grid item xs={6} sx={classes.textFormatField}>
        <Typography variant="body1" color={"primary"} sx={classes.typoLabel}>
          Lists
        </Typography>
        <Grid item xs={12} sx={classes.textFormatCG}>
          {lists?.map((m, i) => {
            const Comp = ButtonComp[m?.component] || BlockButton;
            return (
              <Comp key={`pptool_block_${i}_${m.id}`} editor={editor} {...m} />
            );
          })}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" color={"primary"} sx={classes.typoLabel}>
          Typography
        </Typography>
        <Grid item xs={12} className="typo-icons" sx={classes.evenSpace}>
          {typo?.map((m, i) => {
            return (
              <BlockButton
                key={`pptool_mark_${i}_${m.id}`}
                editor={editor}
                {...m}
              />
            );
          })}
          {fontStyle?.map((m, i) => {
            return (
              <MarkButton
                key={`pptool_mark_${i}_${m.id}`}
                editor={editor}
                {...m}
              />
            );
          })}
          <LinkButton
            key={link.id}
            active={isBlockActive(editor, link.format)}
            editor={editor}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={classes.evenSpace}
        style={{ justifyContent: "space-between" }}
      >
        <Typography variant="body1" color={"primary"} sx={classes.typoLabel}>
          Decorations
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={classes.evenSpace}
        style={{ justifyContent: "space-between" }}
        className="text-decorations-wrpr"
      >
        <ButtonGroup sx={classes.btnGroup}>
          <Button
            className={`no-hover ${bqBgColor ? "active" : ""}`}
            onClick={handleQuote({
              bgColor: !bqBgColor ? "rgb(227, 236, 255)" : null,
            })}
            style={{ background: bqBgColor }}
          >
            Colorbox
          </Button>
          <Button onClick={handleColorPicker("bgColor")}>
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <ButtonGroup sx={classes.btnGroup}>
          <Button
            className={`no-hover ${bqColor ? "active" : ""}`}
            onClick={handleQuote({
              color: !bqColor ? "rgb(47, 94, 188)" : null,
            })}
          >
            <blockquote
              style={{
                paddingLeft: "12px",
                ...getBorderColor(bqColor || "transparent", 3),
                borderWidth: bqColor ? "0px 0px 0px 3px" : "0px"
              }}
            >
              Quote
            </blockquote>
          </Button>
          <Button onClick={handleColorPicker("color")}>
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Button
          onClick={handlePageWidth("fixed")}
          startIcon={<WidthNormalIcon />}
          sx={classes.pageWidthBtn}
          className={pageWidth === "fixed" || !pageWidth ? "active" : ""}
          style={{ width: "45%" }}
        >
          Centered
        </Button>
        <Button
          sx={classes.pageWidthBtn}
          className={pageWidth === "full" ? "active" : ""}
          onClick={handlePageWidth("full")}
          startIcon={<WidthFullIcon />}
          style={{ width: "45%" }}
        >
          Full width
        </Button>
      </Grid>
      <AllColors
        open={open}
        anchorEl={anchorEl}
        onClose={onClosePicker}
        classes={classes}
        onSelect={onSelectColor}
        id="all_1"
      />
    </Grid>
  );
};

export default TextFormat;
