import React, { useState } from "react";
import { Transforms } from "slate";
import { useSlateStatic, ReactEditor } from "slate-react";
import { IconButton, Tooltip, Grid } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import FormElements from "./FormElements";
import FieldPopup from "./FieldPopup";
import { getBreakPointsValue } from "../../helper/theme";

const FormField = (props) => {
  const { attributes, element, children: rootChildren, customProps } = props;
  const { readOnly } = customProps;
  const { element: elementType, grid, children, ...elementProps } = element;
  const FormElement = FormElements[elementType];
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);
  const [openSetttings, setOpenSettings] = useState(false);

  const onSettings = () => {
    setOpenSettings(true);
  };

  const onSave = (data) => {
    const updateData = { ...data };
    delete updateData.children;
    Transforms.setNodes(
      editor,
      {
        ...updateData,
      },
      {
        at: path,
      }
    );
    onClose();
  };

  const onDelete = () => {
    Transforms.removeNodes(editor, { at: path });
  };

  const onClose = () => {
    setOpenSettings(false);
  };

  const FieldToolbar = () => {
    return (
      <div
        className="element-toolbar hr"
        contentEditable={false}
        style={{
          position: "absolute",
          right: "12px",
          top: "24px",
          bottom: 0,
          margin: "auto",
          height: "42px",
          zIndex: 101,
        }}
      >
        <Tooltip title="Field Settings" arrow>
          <IconButton onClick={onSettings}>
            <SettingsIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete Field" arrow>
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  return (
    <Grid
      item
      {...attributes}
      className="form-field"
      sx={{
        position: "relative",
        width: {
          ...getBreakPointsValue(grid, null, "overrideGridSize", true),
        },
      }}
    >
      {!readOnly && <FieldToolbar />}
      <FormElement fieldProps={elementProps} />
      {openSetttings ? (
        <FieldPopup element={element} onSave={onSave} onClose={onClose} customProps={customProps} />
      ) : null}
      <span style={{ display: "none" }}>{rootChildren}</span>
    </Grid>
  );
};

export default FormField;
