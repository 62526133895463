import React, { useState } from "react";
import { Grid, IconButton, TextField, Tooltip } from "@mui/material";
import * as fIcons from "@mui/icons-material";

const MUIIcons = Object.keys(fIcons).reduce(
  (a, b) => {
    if (b.indexOf("Outlined") > -1) {
      a.outlined.push(b);
    } else if (b.indexOf("Rounded") > -1) {
      a.rounded.push(b);
    } else if (b.indexOf("Sharp") > -1) {
      a.sharp.push(b);
    } else if (b.indexOf("TwoTone") > -1) {
      a.tt.push(b);
    } else {
      a.filled.push(b);
    }
    return a;
  },
  { filled: [], outlined: [], rounded: [], tt: [], sharp: [] }
);

const Icons = (props) => {
  const { value, data, onChange } = props;
  const { key } = data;
  const [val, setVal] = useState("");
  const [list, setList] = useState(MUIIcons.filled.slice(0, 90));

  const onSelectIcon = (ico) => () => {
    onChange({
      [key]: ico,
    });
  };

  const handleChange = (e) => {
    const keyVal = e.target.value?.toLowerCase();
    setVal(keyVal);
    if (keyVal) {
      setList(
        MUIIcons.filled
          .filter((f) => {
            return f.toLowerCase().indexOf(keyVal) > -1;
          })
          .slice(0, 90)
      );
    } else {
      setList(MUIIcons.filled.slice(0, 90));
    }
  };

  const onRemoveIcon = () => {
    onChange({
      [key]: null,
    });
  };

  const SelectedIcon = value ? fIcons[value] : null;

  return (
    <>
      <Grid item xs={12}>
        <Grid container style={{ display: "flex", alignItems: "center" }}>
          <Grid
            item
            xs={SelectedIcon ? 10 : 12}
            style={{ paddingRight: "5px" }}
          >
            <TextField
              fullWidth
              size="small"
              placeholder="Search Icons..."
              value={val}
              onChange={handleChange}
            />
          </Grid>
          {SelectedIcon && (
            <Grid
              item
              xs={2}
              className="selctedBtnIcon"
              style={{ padding: "4px" }}
            >
              {SelectedIcon ? (
                <Tooltip title="Click to Remove" arrow>
                  <IconButton onClick={onRemoveIcon}>
                    <SelectedIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                ""
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        textAlign={"center"}
        style={{ maxHeight: "200px", overflowY: "auto", paddingTop: "5px" }}
      >
        {list.map((m) => {
          const Ico = fIcons[m];
          return (
            <Tooltip key={`mui_ico_${m}`} title={m} arrow>
              <IconButton onClick={onSelectIcon(m)}>
                <Ico />
              </IconButton>
            </Tooltip>
          );
        })}
      </Grid>
    </>
  );
};

export default Icons;
