import { useRef, useState } from "react";
import { Editor, Transforms } from "slate";
import { IconButton, Tooltip } from "@mui/material";
import { insertLink } from "../../utils/link";
import Icon from "../../common/Icon";
import { getBlockActive } from "../../utils/SlateUtilityFunctions";
import LinkPopup from "./LinkPopup";

const LinkButton = (props) => {
  const { editor } = props;
  const linkInputRef = useRef(null);
  const [showInput, setShowInput] = useState(false);
  const [linkData, setLinkData] = useState({
    name: "",
    url: "",
    showInNewTab: true,
  });
  const [selection, setSelection] = useState();
  const { isActive, props: blockProps } = getBlockActive(editor, "link");

  const handleInsertLink = () => {
    Transforms.select(editor, selection);
    insertLink(editor, { ...linkData });
    setLinkData({
      name: "",
      url: "",
      showInNewTab: true,
    });
    setShowInput(false);
  };

  const toggleLink = () => {
    setSelection(editor.selection);
    setLinkData({
      name: Editor.string(editor, editor.selection),
      url: blockProps?.href || "",
      showInNewTab: true,
    });
    setShowInput(true);
  };

  const handleInputChange = ({ target }) => {
    let val = target.type === "checkbox" ? target.checked : target.value;
    setLinkData({
      ...linkData,
      [target.name]: val,
    });
  };

  const handleClose = () => {
    setShowInput(false);
  };

  return (
    <div ref={linkInputRef} className="popup-wrapper1">
      <Tooltip title="Link" arrow>
        <IconButton
          className={`${showInput ? "clicked" : ""} ${
            isActive ? "btnActive" : ""
          }`}
          format={"link"}
          onClick={toggleLink}
        >
          <Icon icon="link" />
        </IconButton>
      </Tooltip>
      <LinkPopup
        open={showInput}
        linkData={linkData}
        handleClose={handleClose}
        handleInputChange={handleInputChange}
        handleInsertLink={handleInsertLink}
      />
    </div>
  );
};

export default LinkButton;
