import React, { useEffect, useState } from "react";
import { useSlate } from "slate-react";
import { isBlockActive, activeMark } from "../utils/SlateUtilityFunctions.js";
import useFormat from "../utils/customHooks/useFormat.js";
import { toolbarGroups as defaultToolbarGroups } from "./toolbarGroups.js";
import {
  BlockButton,
  MarkButton,
  Dropdown,
  TextSize,
  FontFamilyDropdown,
  FontSize,
} from "./FormatTools";
import ColorPicker from "../Elements/Color Picker/ColorPicker";
import LinkButton from "../Elements/Link/LinkButton";
import Embed from "../Elements/Embed/Embed";
import TableSelector from "../Elements/Table/TableSelector";
import EquationButton from "../Elements/Equation/EquationButton";
import Id from "../Elements/ID/Id";
import CodeToTextButton from "../Elements/CodeToText/CodeToTextButton";
import HtmlContextMenu from "../Elements/CodeToText/HtmlContextMenu";
import GridButton from "../Elements/Grid/GridButton";
import NewLineButton from "../Elements/NewLine/NewLineButton";
import AccordionButton from "../Elements/Accordion/AccordionButton";
import SignatureButton from "../Elements/Signature/SignatureButton";
import ButtonToolIcon from "../Elements/Button/ButtonToolIcon";
import PageSettingsButton from "../Elements/PageSettings/PageSettingsButton";
import CarouselButton from "../Elements/Carousel/CarouselButton";
import AppHeaderButton from "../Elements/AppHeader/AppHeaderButton";
import FormButton from "../Elements/Form/FormButton.js";
import Text from "./FormatTools/Text";
import ChipTextButton from "../Elements/ChipText/ChipTextButton";
import InlineIconButton from "../Elements/InlineIcon/InlineIconButton";
import EmojiButton from "../Elements/Emoji/EmojiButton.js";
import "./styles.css";
import TopBannerButton from "../Elements/TopBanner/TopBannerButton.js";
import AttachmentsButton from "../Elements/Attachments/AttachmentsButton";
import ColorboxButton from "../Elements/Colorbox/ColorboxButton.js";
import DividerButton from "../Elements/Divider/DividerButton.js";
import EmbedScript from "../Elements/EmbedScript/EmbedScript.js";

export const RenderToolbarIcon = (props) => {
  const { element, editor, customProps, gi, handleCodeToText, icoBtnType } =
    props;
  const renderIcon = () => {
    switch (element.type) {
      case "block":
        return <BlockButton key={element.id} {...element} editor={editor} />;
      case "mark":
        return <MarkButton key={element.id} {...element} editor={editor} />;
      case "dropdown":
        return <Dropdown key={element.id} {...element} editor={editor} />;
      case "fontfamilydropdown":
        return (
          <FontFamilyDropdown key={element.id} {...element} editor={editor} />
        );
      case "numberInput":
        return <FontSize editor={editor} key={element.id} {...element} />;
      case "fontSize":
        return <TextSize key={element.id} {...element} editor={editor} />;
      case "text":
        return (
          <Text
            key={element.id}
            {...element}
            editor={editor}
            style={{ width: "80px" }}
          />
        );
      case "link":
        return (
          <LinkButton
            key={element.id}
            active={isBlockActive(editor, "link")}
            editor={editor}
          />
        );
      case "embed":
        return (
          <Embed
            key={element.id}
            format={element.format}
            editor={editor}
            customProps={customProps}
            icoBtnType={icoBtnType}
            lbT={element.lbT}
          />
        );
      case "embedScript":
        return (
          <EmbedScript
            key={element.id}
            format={element.format}
            editor={editor}
            customProps={customProps}
            icoBtnType={icoBtnType}
            lbT={element.lbT}
          />
        );
      case "color-picker":
        return (
          <ColorPicker
            key={element.id}
            activeMark={activeMark}
            format={element.format}
            editor={editor}
            title={element.title}
          />
        );
      case "table":
        return (
          <TableSelector
            key={element.id}
            editor={editor}
            icoBtnType={icoBtnType}
          />
        );
      case "id":
        return <Id key={`gi_id_${gi}`} editor={editor} />;
      case "equation":
        return <EquationButton key={`gi_equation_${gi}`} editor={editor} />;
      case "codeToText":
        return (
          <CodeToTextButton
            key={`gi_codeToText_${gi}`}
            handleButtonClick={handleCodeToText}
          />
        );
      case "grid":
        return (
          <GridButton
            key={element.id}
            editor={editor}
            customProps={customProps}
            icoBtnType={icoBtnType}
          />
        );
      case "newLine":
        return (
          <NewLineButton
            key={element.id}
            editor={editor}
            icoBtnType={icoBtnType}
          />
        );
      case "accordion":
        return <AccordionButton key={element.id} editor={editor} />;
      case "signature":
        return (
          <SignatureButton
            key={element.id}
            editor={editor}
            icoBtnType={icoBtnType}
          />
        );
      case "button":
        return (
          <ButtonToolIcon
            key={element.id}
            editor={editor}
            icoBtnType={icoBtnType}
          />
        );
      case "page-settings":
        return (
          <PageSettingsButton
            key={element.id}
            editor={editor}
            customProps={customProps}
            icoBtnType={icoBtnType}
          />
        );
      case "carousel":
        return (
          <CarouselButton
            key={element.id}
            editor={editor}
            icoBtnType={icoBtnType}
          />
        );
      case "chip-text":
        return (
          <ChipTextButton
            key={element.id}
            editor={editor}
            customProps={customProps}
          />
        );
      case "app-header":
        return (
          <AppHeaderButton
            key={element.id}
            editor={editor}
            customProps={customProps}
            icoBtnType={icoBtnType}
          />
        );
      case "form":
        return (
          <FormButton
            key={element.id}
            editor={editor}
            customProps={customProps}
            icoBtnType={icoBtnType}
          />
        );
      case "icon-text":
        return (
          <InlineIconButton
            key={element.id}
            editor={editor}
            customProps={customProps}
          />
        );
      case "emoji":
        return (
          <EmojiButton
            key={element.id}
            editor={editor}
            customProps={customProps}
            icoBtnType={icoBtnType}
          />
        );
      case "topbanner":
        return (
          <TopBannerButton
            key={element.id}
            editor={editor}
            customProps={customProps}
            icoBtnType={icoBtnType}
          />
        );
      case "colorbox":
        return (
          <ColorboxButton
            key={element.id}
            editor={editor}
            customProps={customProps}
            icoBtnType={icoBtnType}
          />
        );
      case "docsUpload":
        return (
          <AttachmentsButton
            key={element.id}
            editor={editor}
            customProps={customProps}
            icoBtnType={icoBtnType}
          />
        );
      case "divider":
        return (
          <DividerButton
            key={element.id}
            editor={editor}
            customProps={customProps}
            icoBtnType={icoBtnType}
          />
        );
      default:
        return null;
    }
  };

  return renderIcon();
};

const Toolbar = (props) => {
  const { handleCodeToText, customProps, toolbarOptions } = props;
  const editor = useSlate();
  const isTable = useFormat(editor, "table");
  const [toolbarGroups, setToolbarGroups] = useState(defaultToolbarGroups);
  useEffect(() => {
    // Filter out the groups which are not allowed to be inserted when a table is in focus.
    let filteredGroups = [...defaultToolbarGroups];
    if (isTable) {
      filteredGroups = toolbarGroups.map((grp) =>
        grp.filter(
          (element) =>
            //groups that are not supported inside the table
            !["codeToText"].includes(element.type)
        )
      );
      filteredGroups = filteredGroups.filter((elem) => elem.length);
    }
    setToolbarGroups(filteredGroups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTable]);

  useEffect(() => {
    if (toolbarOptions?.removeOptions) {
      setToolbarGroups(
        defaultToolbarGroups.map((elem) =>
          elem?.filter(
            (item) => !toolbarOptions?.removeOptions?.includes(item?.format)
          )
        )
      );
    }
  }, [toolbarOptions]);

  return (
    <div className={`toolbar ${toolbarOptions?.toolbarPosition}`}>
      {toolbarGroups.map((group, index) => (
        <span
          key={index}
          className={`toolbar-grp1 ${toolbarOptions?.toolbarPosition}`}
        >
          {group.map((element, gi) => {
            return (
              <RenderToolbarIcon
                key={`ri_${element.id}`}
                editor={editor}
                element={element}
                gi={gi}
                handleCodeToText={handleCodeToText}
                customProps={customProps}
              />
            );
          })}
        </span>
      ))}
      <HtmlContextMenu editor={editor} handleCodeToText={handleCodeToText} />
    </div>
  );
};

export default Toolbar;
