import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// import styled from "@emotion/styled";
// import commonStyle from "./commonStyle";

const DialogWrapper = (props) => {
  const { fullScreen, onClose, children, classes } = props;
  return fullScreen ? (
    <Dialog
      className={`dialogComp`}
      open={fullScreen}
      fullScreen={fullScreen}
      onClose={onClose}
      style={{ zIndex: 1000 }}
      sx={classes.fullScreenWrapper}
    >
      <DialogTitle style={{ padding: "6px 8px" }}>
        <Grid>
          <Grid style={{ display: "flex", justifyContent: "end" }}>
            <IconButton
              onClick={onClose}
              style={{ background: "rgba(255,255,255,0.5)" }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  ) : (
    children
  );
};

DialogWrapper.defaultProps = {
  fullScreen: false,
  onClose: () => {},
  children: "",
  footer: "",
};

// export default styled(commonStyle)(DialogWrapper);
export default DialogWrapper;
