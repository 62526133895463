import { Transforms } from "slate";
import insertNewLine from "./insertNewLine";

export const insertDefaultEmbed = (editor, type, defaultURL = "") => {
  const url = defaultURL ? defaultURL : type === "image" ? "" : "";

  insertEmbed(editor, { url, images: [] }, type);
};

export const createEmbedNode = (type, { url, alt, images }) => ({
  type,
  alt,
  url,
  images: images || [],
  children: [{ text: " " }],
  size: { widthInPercent: "100", height: type === "image" ? "auto" : 300 },
  alignment: { horizantal: "center" },
  objectFit: false,
});

export const insertEmbed = (editor, embedData, format) => {
  try {
    const embed = createEmbedNode(format, embedData);
    Transforms.insertNodes(editor, embed, { at: editor.selection.anchor.path });
    insertNewLine(editor);
  } catch (err) {
    console.log(err);
  }
};
