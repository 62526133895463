import { useEffect, useState } from "react";
import { Editor, Range } from "slate";

const SHORTHAND_CMDS = ["@", "/"];

const TYPES = {
  "@": "mentions",
  "/": "elements",
};

const getStartEnd = ({ selection, editor }) => {
  try {
    // Get start and end, modify it as we move along.
    let [start, end] = Range.edges(selection);

    // Move backwards
    while (true) {
      const before = Editor.before(editor, start, {
        unit: "character",
      });
      const wordBefore =
        before && Editor.string(editor, { anchor: before, focus: start });
      if (before && wordBefore && SHORTHAND_CMDS.indexOf(wordBefore) < 0) {
        start = before;
        if (start.offset === 0) {
          // Means we've wrapped to beginning of another block
          break;
        }
      } else if (SHORTHAND_CMDS.indexOf(wordBefore) >= 0) {
        // reached the character end
        start = before;
        break;
      } else {
        break;
      }
    }

    const wordRange = { anchor: start, focus: end };
    const word = Editor.string(editor, wordRange);
    return {
      word,
      search: word?.substring(1, word.length),
      target: wordRange,
      type: TYPES[word[0]],
    };
  } catch (err) {
    return { word: "", wordRange: null, type: null };
  }
};


const useMentions = (props) => {
  const { editor, selection } = props;
  const [mentions, setMentions] = useState({
    target: null,
    index: null,
    search: null,
    type: null,
  });

  useEffect(() => {
    if (selection && Range.isCollapsed(selection)) {
      const { target, search, type } = getStartEnd({ selection, editor });
      if (target && type) {
        setMentions({
          target,
          search,
          index: 0,
          type: type,
        });
      } else {
        setMentions({
          target: null,
          search: null,
          index: null,
          type: null,
        });
      }
    } else {
      setMentions({
        target: null,
        search: null,
        index: null,
        type: null,
      });
    }
  }, [selection, editor]);

  return [mentions, setMentions];
};

export default useMentions;
