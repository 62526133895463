import { useState } from "react";
import DownArrowIcon from "../../../assets/svg/DownArrowIcon";
import { Button, Popover } from "@mui/material";

const CustomSelectTool = ({ classes, value, options = [], onChange }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const selected = options?.find(o => o.value === value);

    return (
        <div>
            <Button
                className={`customSelectTool`}
                onClick={(e) => {
                    e.preventDefault();
                    setAnchorEl(e.currentTarget);
                }}
            >
                {selected?.label || selected?.title} <DownArrowIcon />
            </Button>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={classes.customSelectPopoverWrapper}
            >
                {options.map((option, i) => {
                    return (
                        <div key={i}>
                            <Button
                                className={`customSelectOptionLabel ${value === option.value ? "selected" : ""}`}
                                onClick={() => {
                                    onChange(option.value, option)
                                }}
                            >
                                {option.title}
                            </Button>
                        </div>
                    )
                })}
            </Popover>
        </div>
    )
}

export default CustomSelectTool;