import React, { useState } from "react";
import {
  Button,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Settings from "@mui/icons-material/Settings";
import LinkSettings from "../../LinkSettings";

const MenusArray = (props) => {
  const { value, data, elementProps, onChange, customProps } = props;
  const { key } = data;
  const { menuStyle } = elementProps || { menuStyle: "stacked" };
  const [openNav, setOpenNav] = useState(false);

  const handleChange = (index) => (e) => {
    const upValue = [...(value || [])];
    upValue[index] = {
      ...(upValue[index] || {}),
      [e.target.name]: e.target.value,
    };
    onChange({ [key]: upValue });
  };

  const onAddMenu = () => {
    onChange({ [key]: [...value, { ...value[0] }] });
  };

  const onDelete = (index) => () => {
    const upValue = [...(value || [])];
    upValue.splice(index, 1);
    onChange({ [key]: upValue });
  };

  const handleVariant = (e) => {
    onChange({ [e.target.name]: e.target.value });
  };

  const selectedMenu = openNav?.menu || {};

  const onSave = (update) => {
    const upValue = [...(value || [])];
    upValue[openNav?.index] = {
      ...(upValue[openNav?.index] || {}),
      ...update
    };
    onChange({ [key]: upValue });
  }

  return (
    <Grid item xs={12}>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">
            <Typography
              variant="body1"
              color={"primary"}
              sx={{ fontSize: "14px", fontWeight: "500" }}
            >
              Menu Variant
            </Typography>
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={menuStyle}
            value={menuStyle}
            name="menuStyle"
            onChange={handleVariant}
          >
            <FormControlLabel
              value="stacked"
              checked={menuStyle === "stacked"}
              control={<Radio />}
              label={
                <Typography
                  variant="body1"
                  color={"primary"}
                  sx={{ fontSize: "14px" }}
                >
                  Stacked
                </Typography>
              }
            />
            <FormControlLabel
              value="drawer"
              checked={menuStyle === "drawer"}
              control={<Radio />}
              label={
                <Typography
                  variant="body1"
                  color={"primary"}
                  sx={{ fontSize: "14px" }}
                >
                  Drawer
                </Typography>
              }
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {(value || []).map((m, i) => {
        return (
          <Grid
            key={`add-m-${i}`}
            item
            xs={12}
            style={{ display: "flex", alignItems: "center" }}
          >
            <TextField
              name={"text"}
              type="text"
              value={m.text}
              onChange={handleChange(i)}
              size="small"
              sx={{ mb: 0 }}
            />
            <TextField
              name={"url"}
              type="text"
              value={m.url}
              onChange={handleChange(i)}
              size="small"
              fullWidth
              disabled
            />
            <Tooltip title="Link Settings" arrow>
              <IconButton onClick={() => setOpenNav({ menu: m, index: i })}>
                <Settings />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Menu" arrow>
              <IconButton onClick={onDelete(i)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Button onClick={onAddMenu} sx={{ fontWeight: 700 }}>
          + Add
        </Button>
      </Grid>

      {openNav ?
        <LinkSettings
          handleClose={() => setOpenNav(null)}
          onSave={({ linkType, navValue, openInNewTab }) => {
            onSave({
              linkType,
              url: navValue,
              target: openInNewTab ? "_blank" : ""
            });

            setOpenNav(false);
          }}
          navType={selectedMenu?.linkType}
          navValue={selectedMenu?.url}
          openInNewTab={selectedMenu?.target === "_blank"}
          customProps={customProps}
        /> : null}
    </Grid>
  );
};

export default MenusArray;
