import React, { useState } from "react";
import {
  Button,
  Grid,
  Tab,
  Tabs,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
  Divider,
} from "@mui/material";
import Upload from "./Options/Upload";
import ChooseAssets from "./Options/ChooseAssets";
import AddLink from "./Options/AddLink";
import Icon from "../Icon";
import ImageSelectorStyles from "./Styles";
import { useEditorContext } from "../../hooks/useMouseMove";

const IMAGE_SLECTOR_OPTIONS = {
  upload: Upload,
  choose: ChooseAssets,
  addLink: AddLink,
};

const TAB_SHOW = {
  Image: ["upload", "choose", "addLink"],
  Video: ["addLink"],
  Embed: ["addLink"],
  Document: ["addLink", "upload"],
};

const ImageSelector = (props) => {
  const { theme } = useEditorContext();
  const classes = ImageSelectorStyles(theme);
  const { value, onClose, open, onSelectImage, title } = props;
  const [tabValue, setTabValue] = useState(
    title === "Image" ? "choose" : "addLink"
  );
  const [image, setImage] = useState(value || "");

  const handleTabChange = (e, newValue) => {
    setImage("");
    setTabValue(newValue);
  };

  const onUploaded = (url) => {
    setImage(url);
  };

  const onSave = () => {
    onSelectImage(image);
  };

  const isActive = (val) => (tabValue === val ? "active" : "");

  const Comp = IMAGE_SLECTOR_OPTIONS[tabValue] || <></>;

  return open ? (
    <Dialog open={open} onClose={onClose} fullWidth sx={classes.dialogWrapper}>
      <DialogTitle>
        <Typography sx={classes.titleTypo}>Add {title}</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container>
          {TAB_SHOW[title].length > 1 && (
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "space-evenly" }}
            >
              <Tabs
                sx={classes.tabs}
                onChange={handleTabChange}
                value={tabValue}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  className={`${isActive("upload")} ${
                    TAB_SHOW[title].indexOf("upload") === -1 ? "hidden" : ""
                  }`}
                  sx={classes.tab}
                  icon={<Icon icon={"fileUpload"} />}
                  iconPosition="start"
                  value={"upload"}
                  label={`Upload ${title}`}
                />
                <Tab
                  className={`${isActive("choose")} ${
                    TAB_SHOW[title].indexOf("choose") === -1 ? "hidden" : ""
                  }`}
                  sx={classes.tab}
                  icon={<Icon icon={"media"} />}
                  iconPosition="start"
                  value={"choose"}
                  label={`Choose ${title}`}
                />
                <Tab
                  className={`${isActive("addLink")} ${
                    TAB_SHOW[title].indexOf("addLink") === -1 ? "hidden" : ""
                  }`}
                  sx={classes.tab}
                  style={{ marginRight: 0 }}
                  icon={<Icon icon={"link"} />}
                  iconPosition="start"
                  value={"addLink"}
                  label={`Add ${title} Link`}
                />
              </Tabs>
            </Grid>
          )}
          <Grid item xs={12} sx={classes.imsRightWrpr}>
            <Comp {...props} classes={classes} onUploaded={onUploaded} />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2 }}>
        <Button
          onClick={onClose}
          className="secondaryBtn"
          sx={classes.closeBtn}
        >
          Cancel
        </Button>
        <Button
          disabled={!image}
          onClick={onSave}
          className={`primaryBtn ${!image ? "disabled" : ""}`}
          sx={classes.saveBtn}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

ImageSelector.defaultProps = {
  title: "Image",
};

export default ImageSelector;
