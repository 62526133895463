import React from "react";
import StyleBuilder from "../../common/StyleBuilder";
import accordionTitleStyle from "../../common/StyleBuilder/accordionTitleStyle";

const AccordionTitlePopup = (props) => {
  const { element, onSave, onClose, customProps } = props;
  return (
    <StyleBuilder
      title="Accordion Title"
      type="accordionTitleStyle"
      element={element}
      onSave={onSave}
      onClose={onClose}
      renderTabs={accordionTitleStyle}
      customProps={customProps}
    />
  );
};

export default AccordionTitlePopup;
