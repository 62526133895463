import React, { useEffect, useState } from "react";
import { Grid, TextField, InputAdornment, Typography } from "@mui/material";
import ColorPickerButton from "../../ColorPickerButton";

const Color = (props) => {
  const { value, data, onChange, classes } = props;
  const { key, label } = data;
  const [recentColors, setRecentColors] = useState({});

  useEffect(() => {
    const storedColors = JSON.parse(localStorage.getItem('recentColors'));
    if (storedColors) {
      setRecentColors(storedColors);
    }
  }, []);

  const onSave = (color) => {
    onChange({
      [key]: color,
    });

    let updatedColors = [];

    if (recentColors[key]){
      updatedColors = [color, ...recentColors[key]?.filter(c => c !== color)];
    } else {
      updatedColors?.unshift(color)
    }
    if (updatedColors?.length > 10) {
      updatedColors?.pop()
    }
    updatedColors = { ...recentColors, [key]: updatedColors }
    setRecentColors(updatedColors);
    localStorage.setItem('recentColors', JSON.stringify(updatedColors));
  };

  return (
    <Grid item xs={12} className="btnColorPicker">
      <Typography
        variant="body1"
        color={"primary"}
        sx={{ fontSize: "14px", fontWeight: 500, marginBottom: "5px" }}
      >
        {label}
      </Typography>
      <TextField
        fullWidth
        value={value}
        placeholder={`${label} color code`}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ColorPickerButton
                classes={classes}
                value={value}
                onSave={onSave}
                recentColors={recentColors[key]}
              />
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );
};

export default Color;
