const Styles = () => ({
  drawerContainer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
  },
  childContainer: {
    padding: "20px",
  }
});

export default Styles;
