import { Grid, IconButton, Tooltip } from '@mui/material';
import React from 'react'
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';
import DeleteIcon from "@mui/icons-material/Delete";

import "./Embed.css";

const EmbedLink = ({ attributes, element, children, customProps: { readOnly } }) => {
  const { url, width, height } = element;
  const editor = useSlateStatic()
  const path = ReactEditor.findPath(editor, element)

  return (
    <div
      {...attributes}
      className={`link-embed-wrapper-container ${!readOnly ? 'link-embed-wrapper-hover-container' : ''}`}
      style={{ height: height, width: width }}
    >
      <div className='link-embed-wrapper'>
        <iframe src={url.includes('http') ? url : `//${url}`} frameBorder="0" title={url} />
        <Grid className='docDeleteContainer'>
          <Tooltip title="Delete" arrow>
            <IconButton
              // className='deleteButton'
              onClick={() => {
                Transforms.removeNodes(editor, { at: path })
                ReactEditor.focus(editor);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </div>
      {children}
    </div>
  );
};
export default EmbedLink;