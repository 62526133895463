import React, { useEffect, useRef } from "react";
import sanitizeHtml from "sanitize-html";
import { allowedDomains } from "../../utils/helper";

// const sanitize = (input) => {
//   const doc = new DOMParser().parseFromString(input, "text/html");
//   for (const elm of doc.querySelectorAll("*")) {
//     for (const attrib of elm.attributes) {
//       if (attrib.name.startsWith("on")) {
//         elm.removeAttribute(attrib.name);
//       }
//     }
//   }
//   return doc.body.innerHTML;
// };

const Code = (props) => {
  const codeRef = useRef();
  const { element, attributes, children } = props;
  const { embedData } = element;

  useEffect(() => {
    if (codeRef?.current) {
      const clean = sanitizeHtml(embedData, {
        allowedTags: false, // Allow all tags
        allowedAttributes: false, // Allow all attributes
        allowedScriptDomains: allowedDomains,
      });
      
      const slotHtml = document.createRange().createContextualFragment(clean); // Create a 'tiny' document and parse the html string
      codeRef.current.innerHTML = ""; // Clear the container
      codeRef.current.appendChild(slotHtml);
    }
  }, []);

  return (
    <div contentEditable={false} className={`embed-code`} {...attributes}>
      <div ref={codeRef} />
      {children}
    </div>
  );
};

export default Code;
