import React, { useEffect, useState } from "react";
import { ReactEditor, useSlateStatic } from "slate-react";
import { Box } from "@mui/material";
import Draggable from "./Draggable";
import Droppable from "./Droppable";
import { useEditorContext } from "../../hooks/useMouseMove";
import { Transforms } from "slate";

const DRAGGABLE_TYPES = [
  "paragraph",
  "headingOne",
  "headingTwo",
  "headingThree",
  "grid",
];

const DragHandleStyle = () => ({
  dragHandle: {
    opacity: 0,
    content: '" "',
    position: "absolute",
    top: "3px",
    left: "-52px",
    borderRadius: "0px",
    padding: "0px",
    width: "20px",
    height: "20px",
    border: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "grab",
    color: "#D7D7D6",
    background: "rgb(221, 221, 221, 0.1)",
    "& svg": {
      width: "20px",
    },
    "&:hover": {
      opacity: 1,
      background: "rgb(221, 221, 221, 0.8)",
    },
    "&.active": {
      opacity: 1,
      cursor: "grabbing",
    },
  },
  dropArea: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    pointerEvents: "none",
    zIndex: -1,
    "&.dragging": {
      backgroundColor: "#def4ff",
    },
  },
  dropAreaOver: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "4px",
  },
});

const DragHandle = (props) => {
  const classes = DragHandleStyle();
  const editor = useSlateStatic();
  const [dragging, setDragging] = useState(false);
  const { element } = props;
  const path = ReactEditor.findPath(editor, element);
  const canDraggable = DRAGGABLE_TYPES.indexOf(element?.type) > -1;
  const id = `${element.type}_${[...path].join("|")}`;
  const { moved } = element;
  const { drop } = useEditorContext();

  useEffect(() => {
    if (moved) {
      const upPath = ReactEditor.findPath(editor, element);
      try {
        Transforms.removeNodes(editor, { at: upPath });
      } catch (err) {
        console.log(err, upPath);
      }
    }
  }, [moved]);

  const handleOnDrag = (isDragging) => {
    setDragging(isDragging);
  };

  return canDraggable ? (
    <div key={`${id}_${drop}`} contentEditable={false}>
      <Box
        className={`drag-alert ${dragging ? "dragging" : ""}`}
        sx={classes.dropArea}
      />
      <Droppable id={id} classes={classes} />
      <Draggable id={id} classes={classes} onDrag={handleOnDrag} />
    </div>
  ) : null;
};

export default DragHandle;
