import React, { useState } from "react";
import ToolbarIcon from "../../common/ToolbarIcon";
import Icon from "../../common/Icon";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { insertEmbedScript } from "../../utils/embedScript";

const EmbedScript = (props) => {
  const { editor, icoBtnType } = props;
  const [open, setOpen] = useState(false);
  const [code, setCode] = useState("");

  const handleChange = (e) => {
    setCode(e.target.value);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const onSubmit = () => {
    insertEmbedScript(editor, code);
  };

  const onCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <ToolbarIcon
        title={"Code"}
        onClick={handleClick}
        icon={<Icon icon={"embedScript"} />}
        icoBtnType={icoBtnType}
      />
      <Dialog open={open} fullWidth>
        <DialogTitle>Embed Code</DialogTitle>
        <DialogContent>
          <textarea
            value={code}
            onChange={handleChange}
            style={{
              minHeight: "200px",
              width: "100%",
              resize: "none",
              padding: "4px",
              boxSizing: "border-box",
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onSubmit}>Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EmbedScript;
