import { Editor, Transforms, Element, Node, Path } from "slate";
import { toggleBlock } from "./SlateUtilityFunctions";
import insertNewLine from "./insertNewLine";
import { insertAccordion } from "./accordion";
import { isListItem } from "./helper";

const HOTKEYS = {
  b: "bold",
  i: "italic",
  u: "underline",
};

export const mentionsEvent = (props) => {
  const { event, mentions, setMentions, chars, mentionsRef } = props;
  const { index } = mentions;
  switch (event.key) {
    case "ArrowDown":
      event.preventDefault();
      const prevIndex = index >= chars.length - 1 ? 0 : index + 1;
      setMentions({ ...mentions, index: prevIndex });
      break;
    case "ArrowUp":
      event.preventDefault();
      const nextIndex = index <= 0 ? chars.length - 1 : index - 1;
      setMentions({ ...mentions, index: nextIndex });
      break;
    case "Tab":
    case "Enter":
      event.preventDefault();
      if (mentionsRef && mentionsRef?.current) {
        mentionsRef?.current?.handleOnSelect(chars[index]);
      }

      break;
    case "Escape":
      event.preventDefault();
      setMentions({ ...mentions, target: null, type: null });
      break;
    default:
      break;
  }
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const commands = (props) => {
  try {
    const { event, editor } = props;
    if (HOTKEYS[event.key]) {
      event.preventDefault();
      const isActive = isMarkActive(editor, HOTKEYS[event.key]);
      if (isActive) {
        Editor.removeMark(editor, HOTKEYS[event.key]);
      } else {
        Editor.addMark(editor, HOTKEYS[event.key], true);
      }
    }
  } catch (err) {
    console.log(err);
  }
};

export const indentation = (props) => {
  try {
    const { editor } = props;
    const { selection } = editor;
    if (selection) {
      const [listItemNode] = Editor.nodes(editor, {
        match: (n) => n.type === "list-item" || n.type === "check-list-item",
      });
      if (listItemNode) {
        const [listItem, listItemPath] = listItemNode;

        if (listItem.type === "check-list-item") {
          Transforms.wrapNodes(
            editor,
            { type: listItem.type, children: [{ text: '' }] }
          )
        } else {
          const [parentNode] = Editor.parent(editor, listItemPath);
          const type = parentNode.type;
          Transforms.wrapNodes(
            editor,
            { type, children: [] },
            { at: listItemPath }
          );
        }
      }
    }
  } catch (err) {
    console.log(err);
  }
};

export const escapeEvent = (props) => {
  try {
    const { editor } = props;
    const list_types = ["orderedList", "unorderedList", "check-list-item"];
    const parentPath = Path.ancestors(editor.selection.anchor.path);
    const parentNodes = parentPath
      .filter((m) => m.length)
      .map((m) => Node.get(editor, m));

    const ele = isListItem(editor);
    const eleType = ele[0]?.type;

    const parentLists = parentNodes.filter(p => p.type === "orderedList" || p.type === "unorderedList");

    if (parentLists?.length === 1) { // if not in nested list
      toggleBlock(editor, "list-item", editor.selection);
    } else if (eleType === "check-list-item") {
      checkListEnterEvent(editor, eleType)
    } else {
      Transforms.unwrapNodes(editor, {
        match: (n) =>
          list_types.includes(
            !Editor.isEditor(n) && Element.isElement(n) && n.type
          ),
        split: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const checkListEnterEvent = (editor, type) => {
  const selectionPath = editor?.selection?.anchor?.path;
  const ancestors = Path.ancestors(selectionPath);
  const parentPath = ancestors[ancestors?.length - 2];

  if (parentPath?.length) {
    const lastPathIndex = parentPath.length - 1;
    const otherPaths = parentPath.slice(0, lastPathIndex)
    const nextChildrenPath = parentPath[lastPathIndex] + 1;
    const newPath = [...otherPaths, nextChildrenPath]

    Transforms.insertNodes(editor, { type: "check-list-item", children: [{ text: '' }] }, { at: newPath });
    Transforms.removeNodes(editor);
    // focus on the end of the line
    Transforms.move(editor, { distance: 1, unit: 'line' });
  } else {
    toggleBlock(editor, type);
    insertNewLine(editor);
  }
}

export const enterEvent = (e, editor, isMobile) => {
  try {
    const ele = isListItem(editor);

    // on shift enter break line in same node
    if (e.shiftKey && !isMobile) {
      e.preventDefault();
      Transforms.insertText(editor, "\n");
    } else if (ele && ele[0]) {
      const { type } = ele[0];
      const text = Node.string(ele[0]);
      switch (type) {
        case "list-item":
          if (!text) {
            e.preventDefault();

            escapeEvent({ editor });
          }
          break;
        case "check-list-item":
          const selectionPath = editor?.selection?.anchor?.path;
          const currEle = Node.parent(editor, selectionPath);
          const currEleText = Node.string(currEle);
          if (!currEleText) {
            e.preventDefault()
            checkListEnterEvent(editor, type);
          }
          break;
        case "accordion-summary":
          e.preventDefault();
          if (!text) {
            const accordionIndex = ele[1].slice(0, Math.max(ele[1].length - 1));
            Transforms.removeNodes(editor, { at: accordionIndex });
          } else {
            const nextPath = Path.next(Path.parent(ele[1]));
            insertAccordion(editor, nextPath);
          }
          break;
        default:
      }
    }
  } catch (err) {
    console.log(err);
  }
};
