import React from "react";
import { TextField, IconButton, Grid, Typography } from "@mui/material";
import { sizeMap } from "../../../utils/font.js";
import { TextMinusIcon, TextPlusIcon } from "../../../common/iconslist.js";

const FontSize = (props) => {
  const { value, data, onChange } = props;
  const { key, width } = data;

  const handleChange = (e) => {
    let inc = parseInt(e.target.value) || 16;
    inc = inc > 200 ? 200 : inc;
    onChange({ [key]: inc });
  };

  const getSizeVal = () => {
    try {
      const size =
        `${value}`?.indexOf("px") >= 0 ? value : sizeMap[value] || value;
      return parseInt(size || 16);
    } catch (err) {
      return "";
    }
  };

  const combinedOldVal = getSizeVal();

  const onIncreaseSize = () => {
    let inc = (combinedOldVal || 0) + 1;
    inc = inc > 200 ? 200 : inc;
    onChange({ [key]: inc });
  };

  const onDecreaseSize = () => {
    onChange({ [key]: combinedOldVal - 1 || 0 });
  };

  return (
    <Grid item xs={width || 12}>
      <Grid item xs={12} style={{ marginBottom: "5px" }}>
        <Typography
          variant="body1"
          color={"primary"}
          sx={{ fontSize: "14px", fontWeight: "500" }}
        >
          {data?.label}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: "16px" }}>
        <TextField
          value={combinedOldVal}
          onChange={handleChange}
          size="small"
          inputProps={{
            style: { textAlign: "center", padding: "11px" },
          }}
          InputProps={{
            endAdornment: (
              <div className="textFontArrows">
                <IconButton onClick={onIncreaseSize} size="small">
                  <TextPlusIcon />
                </IconButton>
                <IconButton onClick={onDecreaseSize} size="small">
                  <TextMinusIcon />
                </IconButton>
              </div>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default FontSize;
