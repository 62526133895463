import Text from "./text";
import BannerSpacing from "./bannerSpacing";
import BorderRadius from "./borderRadius";
import Color from "./color";
import Alignment from "./alignment";
import BackgroundImage from "./backgroundImage";
import GridSize from "./gridSize";
import ElementSize from "./elementSize";
import ImageTexts from "./imageTexts";
import MenusArray from "./menusArray";
import ButtonLink from "./buttonLink";
import SaveAsTemplate from "./saveAsTemplate";
import TextAlign from "./textAlign";
import TextOptions from "./textOptions";
import SelectBox from "./selectBox";
import Icons from "./icons";
import FontSize from "./fontSize";

const FieldMap = {
  text: Text,
  bannerSpacing: BannerSpacing,
  borderRadius: BorderRadius,
  color: Color,
  alignment: Alignment,
  backgroundImage: BackgroundImage,
  gridSize: GridSize,
  elementSize: ElementSize,
  imageTexts: ImageTexts,
  menusArray: MenusArray,
  buttonLink: ButtonLink,
  saveAsTemplate: SaveAsTemplate,
  textAlign: TextAlign,
  textOptions: TextOptions,
  selectBox: SelectBox,
  icons: Icons,
  fontSize: FontSize,
};

export default FieldMap;
