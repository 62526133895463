import { invertColor } from "../../helper";

const SimpleTextStyle = ({ pageColor }) => ({
  root: {
    position: "relative",
    padding: "0px",
    "& .placeholder-simple-text": {
      color: invertColor(pageColor),
      background: "transparent",
      position: "absolute",
      zIndex: -1,
      left: "0px",
      top: "0px",
      bottom: 0,
      margin: "auto",
      pointerEvents: "none",
      height: "18px",
      overflow: "hidden",
      fontSize: "14px",
      "& .bg-pad-sl": {
        padding: "2px 4px 2px 4px",
        background: "transparent",
        color: invertColor(pageColor),
      },
    },
  },
  section: {
    display: "flex",
    width: "100%",
    backgroundSize: "cover",
    justifyContent: "center",
    alignItems: "center",
    "& .simple-text": {
      width: "80%",
      maxWidth: "1440px",
    },
  },
});

export default SimpleTextStyle;
