import React from "react";
import { ReactEditor, useReadOnly, useSlateStatic } from "slate-react";
import { Transforms } from "slate";
import { Checkbox, FormControlLabel } from "@mui/material";
import Icon from "../../common/Icon";
import CheckListStyle from "./CheckListStyles";

const CheckList = ({ attributes, children, element, isEmpty }) => {
  const editor = useSlateStatic();
  const readOnly = useReadOnly();
  const classes = CheckListStyle()
  const { checked } = element;
  const path = ReactEditor.findPath(editor, element);

  const handleCheck = (e) => {
    Transforms.setNodes(
      editor,
      {
        checked: e.target.checked,
      },
      { at: path }
    );
  };

  const nestedCheckList = element?.children?.length && element.children[0].type === "check-list-item";

  return (
    <>
      <div
        {...attributes}
        style={{
          textAlign: element.alignment || "left",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div contentEditable={false} className="checkbox-edit" style={nestedCheckList ? { visibility: "hidden" } : {}}>
          <FormControlLabel
            control={
              <Checkbox
                icon={<Icon icon="checkListButton" />}
                checkedIcon={<Icon icon="checkListButtonActive" />}
                sx={classes.checkBoxHover}
                checked={checked}
                onChange={handleCheck}
              />
            }
          />
          <span className="checkbox-inner"></span>
        </div>
        <div
          contentEditable={!readOnly}
          suppressContentEditableWarning
          style={{
            flex: 1,
            opacity: checked ? 1 : 1,
            textDecoration: !checked ? "none" : "none",
          }}
          className={`checkbox-list content-editable ${isEmpty ? "empty" : ""}`}
          placeholder={nestedCheckList ? "" : "Todo List"}
        >
          {children}
        </div>
      </div >
    </>
  );
};

export default CheckList;
