import { Editor, Transforms, Element } from "slate";

export const findPageSettings = (editor) => {
  try {
    return {
      element: editor?.children?.find((f) => f.type === "page-settings"),
    };
  } catch (err) {
    return { path: null, element: { pageProps: { pageWidth: "fixed" } } };
  }
};

export const getPageSettings = (editor) => {
  try {
    const [pageSettingsNode] = Editor.nodes(editor, {
      at: [],
      match: (n) => {
        return (
          !Editor.isEditor(n) &&
          Element.isElement(n) &&
          n.type === "page-settings"
        );
      },
    });
    if (pageSettingsNode && pageSettingsNode[0] && pageSettingsNode[1]) {
      const [element, path] = pageSettingsNode;
      return { path, element };
    } else {
      return { path: null, element: { pageProps: { pageWidth: "fixed" } } };
    }
  } catch (err) {
    console.log(err);
    return { path: null, element: {} };
  }
};

export const insertPageSettings = (editor, pageProps = {}) => {
  Transforms.insertNodes(
    editor,
    [
      {
        type: "page-settings",
        pageProps: { pageWidth: "fixed", ...pageProps },
        children: [{ text: "" }],
      },
    ],
    { at: [editor.children.length] }
  );
};

export const updatePageSettings = (editor, pageProps = {}) => {
  try {
    const { path } = getPageSettings(editor);
    if (path) {
      Transforms.setNodes(
        editor,
        {
          pageProps: { ...pageProps },
        },
        {
          at: path,
        }
      );
    } else {
      insertPageSettings(editor, pageProps);
    }
  } catch (err) {
    console.log(err);
  }
};

export const focusOnFirstParagraph = (editor) => {
  try {
    const [paragraphNode] = Editor.nodes(editor, {
      at: [],
      match: (n) => {
        return (
          !Editor.isEditor(n) &&
          Element.isElement(n) &&
          n.text !== undefined &&
          !n.type
        );
      },
    });
    if (paragraphNode && paragraphNode[1] !== undefined) {
      Transforms.select(editor, { path: paragraphNode[1], offset: 0 });
    }
  } catch (err) {
    console.log(err);
  }
};
