import { useEffect, useState } from "react";

const useWindowMessage = (props) => {
  const { type } = props;
  const [message, setMessage] = useState(null);
  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener("message", onMessage, false);
    } else {
      window.attachEvent("onmessage", onMessage);
    }

    return () => {
      if (window.addEventListener) {
        window.removeEventListener("message", onMessage, false);
      } else {
        window.detachEvent("onmessage", onMessage);
      }
    };
  }, []);

  const onMessage = (e) => {
    if (e?.data && e?.data[type]) {
      setMessage(e?.data[type]);
    }
  };

  const sendMessage = (action) => {
    if (window.parent) {
      window.parent.postMessage(
        {
          ...action,
        },
        "*"
      );
    }
  };

  return [message, sendMessage];
};

export default useWindowMessage;
