const miniToolbarStyles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: "-15px",
    left: 0,
    right: 0,
    margin: "auto",
    border: "1px solid #D8DDE1",
    borderRadius: "22px",
    width: "fit-content",
    height: "37px",
    zIndex: 1000,
    background: "#FFF",
    boxShadow: "1px 2px 15px rgba(37, 99, 235, 0.25)",
    padding: "0px 8px",
    "& button": {
      "& svg": {
        stroke: "#64748B",
      },
      "&.active": {
        "& svg": {
          stroke: "#2563EB",
        },
      },
    },
    "&.mini-tool-wrpr-ei": {
      background: theme?.palette?.editor?.background,
      borderColor: theme?.palette?.editor?.borderColor,
      "& button": {
        "& svg": {
          stroke: theme?.palette?.editor?.svgStroke,
        },
        "& .removeDefaultSvgCls": {
          stroke: "none !important",
        },
        "&.active": {
          "& svg": {
            stroke: theme?.palette?.editor?.activeColor,
          }
        },
      },
    },
  },
});

export default miniToolbarStyles;
