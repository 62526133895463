import React from "react";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Grid,
  Radio,
  TextField,
  Typography,
} from "@mui/material";

const ButtonLink = (props) => {
  const { value, data, elementProps, customProps, onChange } = props;
  const { key } = data;
  const { metadata } = customProps || { metadata: {} };
  const { buttonLink } = metadata || { actionTrigger: {} };
  const { actionTrigger } = buttonLink || {};
  const { onClick } = actionTrigger || { options: [] };

  const handleChange = (e) => {
    onChange({
      [key]: {
        ...value,
        [e.target.name]: e.target.value,
        onClick: onClick || {},
      },
    });
  };

  const onURLChange = (e) => {
    onChange({
      url: e.target.value,
    });
  };

  const renderLinkTypeFields = () => {
    if (value?.linkType === "webAddress") {
      return (
        <Grid item xs={12}>
          <TextField
            fullWidth
            size="small"
            placeholder="https://"
            onChange={onURLChange}
            value={elementProps?.url}
          />
        </Grid>
      );
    } else if (value?.linkType === "actionTrigger") {
      return null;
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <FormControl>
          <Typography
            variant="body1"
            color={"primary"}
            style={{ fontSize: "14px", fontWeight: 500 }}
            id="demo-radio-buttons-group-label"
          >
            Where do you want to Link?
          </Typography>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            value={value?.linkType || ""}
            name="linkType"
            className="cradio-primary"
            onChange={handleChange}
          >
            <FormControlLabel
              value="actionTrigger"
              control={<Radio />}
              label={
                <Typography
                  variant="body1"
                  color={"primary"}
                  style={{ fontSize: "14px" }}
                >
                  Next Step
                </Typography>
              }
            />
            <FormControlLabel
              value="webAddress"
              control={<Radio />}
              label={
                <Typography
                  variant="body1"
                  color={"primary"}
                  style={{ fontSize: "14px" }}
                >
                  Web Address
                </Typography>
              }
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {renderLinkTypeFields()}
    </>
  );
};

export default ButtonLink;
