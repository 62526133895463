import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Transforms } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";
import { insertMention } from "../../utils/mentions";
import ElementListCard from "./ElementsListCard";
import MentionsListCard from "./MentionsListCard";
import { Typography, Popper, Box, Paper } from "@mui/material";
import usePopupStyle from "./Styles";

const POPUP_LIST_TYPES = {
  mentions: MentionsListCard,
  elements: ElementListCard,
};

const MentionsPopup = forwardRef((props, ref) => {
  const { theme, index, target, chars, mentions, setMentions, type } = props;
  const classes = usePopupStyle(theme);
  const papperRef = useRef(null);
  const buttonRef = useRef(null);
  const editor = useSlateStatic();
  const ListElement = POPUP_LIST_TYPES[type] || null;
  const [anchorEl, setAnchorEl] = useState(null);
  let open = Boolean(anchorEl);
  let prevGroup = "";

  useEffect(() => {
    if (target && chars.length > 0) {
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();
      setAnchorEl({
        clientWidth: rect.width,
        clientHeight: rect.height,
        getBoundingClientRect: () => rect,
      });
    }
  }, [chars.length, editor, index, target]);

  useEffect(() => {
    if (papperRef && papperRef?.current) {
      papperRef.current.scrollIntoView(false, { block: "center" });
    }
  }, [index]);

  useImperativeHandle(ref, () => ({
    handleOnSelect: (char) => {
      handleInsertMention(char);
    },
  }));

  const renderGroupHeader = (char) => {
    if (prevGroup !== char.group && char.group) {
      prevGroup = char.group;
      return (
        <Typography sx={classes.groupHeader} component={"div"} variant="h6">
          {prevGroup}
        </Typography>
      );
    } else {
      return null;
    }
  };

  const renderList = (char, i) => {
    return (
      <Box key={`${char.name}_${i}`} component={"div"} sx={classes.renderComp}>
        {renderGroupHeader(char)}
        <Box
          id={`cmd-li-ind-${index}}`}
          component={"div"}
          onClick={handleClick(char, i)}
          className={`${i === index ? "active" : " "} ${
            char.renderComponent ? "renderComp" : ""
          }`}
          sx={classes.listItem}
          ref={i === index ? papperRef : null}
        >
          {char.renderComponent ? (
            char.renderComponent({
              ref: index === i ? buttonRef : null,
              selectionTarget: target,
            })
          ) : ListElement ? (
            <ListElement classes={classes} data={char} header={prevGroup} />
          ) : (
            char.name
          )}
        </Box>
      </Box>
    );
  };

  const handleClick = (char, i) => (e) => {
    setMentions({ ...mentions, index: i });
    // TBD
    setTimeout(() => {
      handleInsertMention(char, e);
    }, 0);
  };

  const handleInsertMention = (char, e) => {
    if (!char.renderComponent) {
      Transforms.select(editor, target);
      insertMention(editor, char, type);
      setMentions({ ...mentions, target: null });
    } else if (buttonRef && buttonRef?.current) {
      if (e) {
        e.preventDefault();
      }
      // trigger click of the ToolbarIcon button
      buttonRef?.current?.triggerClick(target);
    }
  };

  return target && chars.length > 0 ? (
    <Popper
      open={open}
      anchorEl={anchorEl}
      disablePortal={false}
      sx={classes.root}
      placement="bottom-start"
      className="cmd-wrapper"
    >
      <Paper sx={classes.papper}>{chars.map(renderList)}</Paper>
    </Popper>
  ) : null;
});

MentionsPopup.displayName = "MentionsPopup";

export default MentionsPopup;
