import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Avatar } from "@mui/material";

const MentionsListCard = (props) => {
  const { data } = props;
  const { name, email } = data;
  return (
    <Card
      sx={{
        display: "flex",
        width: "100%",
        maxWidth: "324px",
        justifyContent: "start",
        alignItems: "center",
        background: "transparent",
        boxShadow: "none",
      }}
    >
      <CardMedia
        component="div"
        sx={{
          width: 46,
          height: 46,
          margin: "8px",
          borderRadius: "4px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        alt={name}
      >
        <Avatar />
      </CardMedia>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto", padding: "8px !important" }}>
          <Typography component="div" variant="h6" style={{ fontSize: "14px" }}>
            {name}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
            style={{ fontSize: "12px" }}
          >
            {email}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
};

export default MentionsListCard;
