import { fontOptions } from "../../utils/font";

const formButtonStyle = [
  {
    tab: "General",
    value: "size",
    fields: [
      {
        label: "Button Text",
        key: "label",
        type: "text",
      },
      {
        label: "Font Size",
        key: "textSize",
        type: "text",
        placeholder: "16px or 1em",
      },
      {
        label: "Font Family",
        key: "fontFamily",
        type: "textOptions",
        options: fontOptions,
        renderOption: (option) => {
          return (
            <span style={{ fontFamily: option.value }}>{option.text}</span>
          );
        },
      },
      {
        label: "Text Align",
        key: "textAlign",
        type: "textAlign",
        placeholder: "16px or 1em",
      },
      {
        label: "Full Width",
        key: "fullWidth",
        type: "selectBox",
        placeholder: "Set Button Width to full",
      },
    ],
  },
  {
    tab: "Position",
    value: "position",
    fields: [
      {
        label: "Set Postion (Vertical & Horizantal)",
        key: "alignment",
        type: "alignment",
      },
    ],
  },
  {
    tab: "Margin Spacing",
    value: "marginSpacing",
    fields: [
      {
        label: "Margin Spacing",
        key: "marginSpacing",
        type: "bannerSpacing",
      },
    ],
  },
  {
    tab: "Banner Spacing",
    value: "bannerSpacing",
    fields: [
      {
        label: "Banner Spacing",
        key: "bannerSpacing",
        type: "bannerSpacing",
      },
    ],
  },
  {
    tab: "Border Radius",
    value: "borderRadius",
    fields: [
      {
        label: "Border Radius",
        key: "borderRadius",
        type: "borderRadius",
      },
    ],
  },
  {
    tab: "Colors",
    value: "colors",
    fields: [
      {
        label: "Text Color",
        key: "textColor",
        type: "color",
        needPreview: true,
      },
      {
        label: "Background Color",
        key: "bgColor",
        type: "color",
      },
      {
        label: "Border Color",
        key: "borderColor",
        type: "color",
      },
    ],
  },
  {
    tab: "Size",
    value: "gridSize",
    fields: [
      {
        label: "Grid Size",
        key: "grid",
        type: "gridSize",
      },
    ],
  },
  {
    tab: "Save As Template",
    value: "saveAsTemplate",
    needActions: false,
    fields: [
      {
        label: "Template Image",
        key: "saveAsTemplate",
        type: "saveAsTemplate",
      },
    ],
  },
];

export default formButtonStyle;
