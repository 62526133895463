const SectionStyle = () => ({
  root: {
    "&:hover": {
      "& .dh-para": {
        opacity: 1,
      },
      "& .sectionIcon": {
        opacity: 1,
      },
    },
    "& .element-toolbar": {
      "&:hover": {
        display: "block",
      },
    },
    "& .sectionIcon": {
      opacity: 0,
      padding: "0px",
      background: "transparent",
      border: "none",
      width: "20px",
      height: "20px",
      "& button": {
        boxShadow: "none",
        background: "transparent",
        width: "20px",
        height: "20px",
      },
      "& svg": {
        fill: "#ccc",
        width: "20px",
        marginTop: "-5px",
      },
      "&:hover": {
        opacity: 1,
        background: "#eee",
      },
      "&.active": {
        opacity: 1,
      },
    },
  },
});

export default SectionStyle;
