import { Transforms } from "slate";

export const insertMention = (editor, character, type) => {
  if (type === "mentions") {
    const mention = {
      type: "mention",
      character,
      children: [{ text: "" }],
    };
    Transforms.insertNodes(editor, mention);
    Transforms.move(editor);
  } else if (type === "elements" && character && character.onInsert) {
    character.onInsert(editor);
  }
};
