import React, { useState } from "react";
import { Transforms } from "slate";
import { useSelected, useSlateStatic, ReactEditor } from "slate-react";
import AccordionTitlePopup from "./AccordionTitlePopup";
import { IconButton, Tooltip } from "@mui/material";
import { GridSettingsIcon } from "../../common/iconslist";
import { useEditorSelection } from "../../hooks/useMouseMove";

const AccordionSummary = (props) => {
  const { attributes, children, element, customProps } = props;
  const { readOnly } = customProps;
  const [openSetttings, setOpenSettings] = useState(false);
  const editor = useSlateStatic();
  const [showTool] = useEditorSelection(editor);
  const selected = useSelected();
  const path = ReactEditor.findPath(editor, element);
  const { textColor, bgColor, borderColor } = element;

  const ToolBar = () => {
    return selected && !showTool ? (
      <div
        className="element-toolbar hr"
        contentEditable={false}
        style={{ top: "-42px" }}
      >
        <Tooltip title="Settings" arrow>
          <IconButton onClick={onSettings}>
            <GridSettingsIcon />
          </IconButton>
        </Tooltip>
      </div>
    ) : null;
  };

  const onSettings = () => {
    setOpenSettings(true);
  };

  const onSave = (data) => {
    const updateData = { ...data };
    delete updateData.children;
    Transforms.setNodes(
      editor,
      {
        ...updateData,
      },
      {
        at: path,
      }
    );
    onClose();
  };

  const onClose = () => {
    setOpenSettings(false);
  };

  return (
    <div
      className={`accordion-summary-container`}
      {...attributes}
      style={{
        width: "100%",
        position: "relative",
        backgroundColor: bgColor,
        border: `1px solid ${borderColor}`,
        color: textColor,
      }}
    >
      {children}
      {!readOnly && <ToolBar />}
      {openSetttings ? (
        <AccordionTitlePopup
          element={element}
          onSave={onSave}
          onClose={onClose}
          customProps={customProps}
        />
      ) : null}
    </div>
  );
};

export default AccordionSummary;
