const ButtonNavSettingsStyles = () => ({
    dialogContainer: {
        '& .MuiDialogContent-root': {
            padding: "0px 20px",
        },
        '& .MuiDialogActions-root': {
            padding: "10px",
        },
        '& .MuiTypography-h6': {
            fontWeight: 600,
            fontSize: "16px",
            paddingRight: "20px"
        },
        "& .MuiGrid-container": {
            marginTop: '0px'
        },
        "& .MuiGrid-item": {
            padding: "14px"
        }
    },
    saveBtn: {
        color: '#fff',
        background: "#2563EB",
        fontSize: "14px",
        fontWeight: 500,
        padding: "4px 24px",
        textTransform: "none",

        "&:hover": {
            color: '#fff',
            background: "#2563EB",
        }
    },
    closeBtn: {
        backgroundColor: "#F4F6F9",
        color: "#64748B",
        fontSize: "14px",
        fontWeight: 500,
        padding: "4px 22px",
        textTransform: "none",
        border: "1px solid #D8DDE1",
        "&:hover": {
            border: "1px solid #64748B",
        },
    },
    closeIcon: {
        position: 'absolute',
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
    },
    gridDivider: {
        borderRight: "1px solid rgba(0, 0, 0, 0.12)"
    },
    mobileActionBtns: {
        display: "flex",
        justifyContent: "flex-end",
        gap: "8px",
        paddingTop: "14px",
        marginTop: "14px",
        borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`
    }
});

export default ButtonNavSettingsStyles;